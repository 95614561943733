// import {
//   a,
//   useChain,
//   useSpring,
//   useSpringRef
// } from '@react-spring/three'
import React, { forwardRef } from 'react'
// import { DoubleSide } from 'three'

import { IModelProps } from '../../../node/Model'

const BarChart = forwardRef((props: IModelProps, ref: any) => {
  return (
    <group
      name='NETWORK-DIAGRAM-CHART'
      ref={ref}
      position={props.position}
      rotation={[0, -Math.PI / 2, 0]}
      scale={props.scale}
    >
      <mesh></mesh>
    </group>
  )
})

export default BarChart
