import { Line, Sphere } from '@react-three/drei'
import { ThreeEvent, useFrame, useThree } from '@react-three/fiber'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
  ColorRepresentation,
  CurvePath,
  LineCurve3,
  Raycaster,
  Vector3
} from 'three'
import { MapControls } from 'three-stdlib'
import { getAnglePoints } from '../../utils/getAnglePoints'
import { getPointsFromArray } from '../../utils/getPointsFromArray'
import { isPointOnSegment } from '../../utils/linkUtils'
import { getNodeUUID, getPointOnNodeFace } from '../../utils/nodeUtils'
import { pointsOnRoundedCornerLine } from '../../utils/pointsOnRoudedCornerLine'
import UUID from '../../utils/uuid'
// import { linkY } from '../config'

import { LinkTransformController } from '../designer/LinkTransformController'
import { Traffic, Traffics } from './Traffics'

export const PointDot = ({
  position,
  visible,
  radius = 0.1,
  opacity = 0.75,
  color = '#f00',
  onClick
}: {
  position: Vector3
  visible: boolean
  radius?: number
  opacity?: number
  color?: string
  onClick?: (event: ThreeEvent<MouseEvent>) => void
}) => {
  return (
    <Sphere
      name={'lineedit--point-dot'}
      args={[radius]}
      position={position}
      visible={visible}
      renderOrder={10}
      onClick={onClick}
    >
      <meshBasicMaterial
        attach='material'
        color={color}
        opacity={opacity}
        transparent={true}
        depthTest={false}
        depthWrite={false}
      />
    </Sphere>
  )
}

export type LinkProps = {
  id?: string
  name?: string
  from?: string
  to?: string
  points?: number[]
  color?: ColorRepresentation
  width?: number
  type?: 'SOLID' | 'DASHED' // | 'DOTTED'
  userData?: { [key: string]: any } & {
    angleType?: 'AUTO' | 'MANUAL'
  }

  editable?: boolean

  showTraffic?: boolean

  onSelected?: (id: string, active: boolean) => void
  onChanged?: (id: string, props: any) => void
  state?: string
  mapControls?: React.MutableRefObject<MapControls>
}

export const Link = ({
  id = UUID.generate(),
  name = 'Link',
  from = '',
  to = '',
  points = [],
  color = '#0ff',
  width = 1,
  type = 'SOLID',
  userData = {},
  editable = false,
  showTraffic = true,
  mapControls,
  onSelected,
  onChanged
}: LinkProps) => {
  const { scene, camera, mouse } = useThree()
  const [aNode, setANode] = useState<THREE.Object3D | undefined>()
  const [zNode, setZNode] = useState<THREE.Object3D | undefined>()
  const [aPoint, setAPoint] = useState<Vector3>(new Vector3())
  const [zPoint, setZPoint] = useState<Vector3>(new Vector3())
  const [linkPoints, setLinkPoints] = useState<number[]>(points)
  useEffect(() => {
    setLinkPoints(points)
  }, [points])
  const [angledPathPoints, setAngledPathPoints] = useState<Vector3[]>([])
  const [roundedPathPoints, setRoundedPathPoints] = useState<Vector3[]>([])
  const [RoundedLinkPath, setRoundedLinkPath] = useState<CurvePath<Vector3>>(
    new CurvePath<Vector3>()
  )

  // 노드의 위치를 기반으로 선의 포인트를 생성한다.
  const linePoints = useMemo(() => {
    if (aNode && zNode) {
      // @ts-ignore
      let subPoints = getPointsFromArray(linkPoints)
      // NOTE - 노드의 위치를 기반으로 선의 포인트를 생성한다.
      //        기본 선의 모양을 노드와 노드를 잇는 직선으로 생성한다.
      // if (subPoints.length < 1) {
      //   subPoints = getAnglePoints(aPoint, zPoint)
      // }
      if (userData.angleType !== 'MANUAL' && subPoints.length < 1) {
        subPoints = getAnglePoints(aPoint, zPoint)
      }
      setAngledPathPoints(subPoints)
      // console.log('SUB POINTS', subPoints)
      const roundedPoints = pointsOnRoundedCornerLine([
        aPoint, // A node의 위치
        ...subPoints,
        zPoint // Z node의 위치
      ])

      // 양 끝점의 위치를 노드의 중심에서 표면으로 옮긴다.
      const ap = getPointOnNodeFace(aNode, roundedPoints[1])
      const zp = getPointOnNodeFace(
        zNode,
        roundedPoints[roundedPoints.length - 2]
      )

      if (ap) {
        roundedPoints.shift()
        roundedPoints.unshift(ap)
      }

      if (zp) {
        roundedPoints.pop()
        roundedPoints.push(zp)
      }

      setRoundedPathPoints(roundedPoints)

      return roundedPoints
    }
    return [new Vector3(), new Vector3()]
  }, [aNode, aPoint, zNode, zPoint, linkPoints])

  useEffect(() => {
    if (roundedPathPoints) {
      const cp = new CurvePath<Vector3>()
      for (let i = 0, l = roundedPathPoints.length; i < l - 1; i++) {
        cp.add(new LineCurve3(roundedPathPoints[i], roundedPathPoints[i + 1]))
      }
      setRoundedLinkPath(cp)
    }
  }, [roundedPathPoints])

  const [active, setActive] = useState<boolean>(false)
  const [lineWidth, setLineWidth] = useState<number>(width)
  const [lineColor, setLineColor] = useState<ColorRepresentation>(color)
  const [renderOrder, setRenderOder] = useState<number>(0)

  const [raycaster, setRaycaster] = useState<Raycaster | null>(null)

  useEffect(() => {
    setLineWidth(width)
  }, [width])

  useEffect(() => {
    setLineColor(color)
  }, [color])

  useEffect(() => {
    if (active) {
      setLineWidth((width || 1) * 3)
      setLineColor('#316dca')
      setRenderOder(999999)
      setRaycaster((_ray) => {
        const raycaster = new Raycaster()
        // @ts-ignore
        raycaster.params.Line2 = {}
        // @ts-ignore
        raycaster.params.Line2.threshold = 5
        return raycaster
      })
    } else {
      setLineWidth(width || 1)
      setLineColor(color || '#ffffff')
      setRenderOder(1)
      setRaycaster(null)
    }
  }, [active])

  const activate = () => {
    if (editable) {
      setActive(true)
    }
    // 디자이너에 선택된 링크를 전달한다.
    onSelected && onSelected(id, true)
  }

  const deactive = () => {
    if (editable) {
      setActive(false)
    }
    onSelected && onSelected(id, false)
  }

  function hover(isHover: boolean) {
    if (isHover) {
      setLineWidth((width || 1) * 3)
      setRenderOder(999)
    } else {
      setLineWidth(active ? (width || 1) * 3 : width || 1)
      setRenderOder(active ? 999 : 1)
    }
  }

  const onEnter = () => {
    hover(true)
  }

  const onLeave = () => {
    hover(false)
  }

  const onChangeAnglePoints = (points: Vector3[]) => {
    const newPoints = points.map((p) => p.toArray()).flat()
    setLinkPoints(newPoints)
    // 디자이너에 변경된 링크 정보를 전달한다.
    onChanged &&
      onChanged(id, {
        points: newPoints
      })
  }

  const lineAndControls = useRef<THREE.Group>(undefined!)
  const lineRef = useRef<any>(undefined!)
  useFrame((_, delta) => {
    // 화면에 노드가 표시되면 시작 노드와 종료 노드를 찾는다.
    if (aNode === undefined) {
      setANode(getNodeUUID(scene, from))
    }
    if (zNode === undefined) {
      setZNode(getNodeUUID(scene, to))
    }

    if (aNode) {
      const apoint = aNode.position.clone() || aPoint
      if (!aPoint.equals(apoint)) {
        setAPoint(apoint)
      }
    }

    if (zNode) {
      const zpoint = zNode.position.clone() || zPoint
      if (!zPoint.equals(zpoint)) {
        setZPoint(zpoint)
      }
    }

    if (active) {
      raycaster?.setFromCamera(mouse, camera)
      const intersects = raycaster?.intersectObjects(
        lineAndControls.current.children,
        true
      )

      if (intersects && intersects.length > 0) {
        if (intersects[0].object.name === name + '--line-helper') {
          setPointDotPosition((intersects[0] as any).pointOnLine)
          setPointDotVisible(true)
        } else {
          if (intersects[0].object.name !== 'lineedit--point-dot') {
            setPointDotVisible(false)
          }
        }
      }
    }

    if (type !== 'SOLID') {
      setDashOffset((offset) => {
        return offset - delta
      })
    }
  })

  const [dashOffset, setDashOffset] = useState<number>(0)

  const [pointDotPosition, setPointDotPosition] = useState<Vector3>(
    new Vector3()
  )
  const [pointDotVisible, setPointDotVisible] = useState<boolean>(false)

  const handleAddPointToLink = (event: ThreeEvent<MouseEvent>) => {
    const newPoints = [aPoint, ...angledPathPoints, zPoint]
    const p = event.eventObject.position.clone()
    for (let i = 0; i < newPoints.length - 1; i++) {
      const check = isPointOnSegment(newPoints[i], newPoints[i + 1], p)
      if (check) {
        newPoints.splice(i + 1, 0, p)
        break
      }
    }
    newPoints.shift()
    newPoints.pop()
    onChangeAnglePoints(newPoints)
  }

  const [traffics, setTraffics] = useState<Traffic[]>([])
  useEffect(() => {
    const defaultTraffics: { id: number; color: string }[] =  [
      { id: 1, color: '#fff' },
      { id: 2, color: '#fff' },
      { id: 3, color: '#fff' },
      { id: 4, color: '#fff' },
      { id: 5, color: '#fff' },
      { id: 6, color: '#fff' },
      { id: 7, color: '#fff' },
      { id: 8, color: '#fff' },
      { id: 9, color: '#fff' },
      { id: 10, color: '#fff' }
    ]
    const pathLength = RoundedLinkPath?.getLength() || 0
    const cnt = Math.floor(pathLength)
    defaultTraffics.length = cnt
    setTraffics(userData.traffics || defaultTraffics)
  }, [userData, RoundedLinkPath])
  const three = useThree()
  const [zoom, setZoom] = React.useState(1)
  useFrame(() => {
    setZoom(three.camera.zoom)
  })
  return (
    <group
      name='NETWORK-DIAGRAM-LINK'
      uuid={id}
      onPointerMissed={deactive}
      onPointerOver={onEnter}
      onPointerOut={onLeave}
      onClick={activate}
      userData={userData}
    >
      {!active && (
        <Line
          ref={lineRef}
          name={name}
          points={linePoints!}
          alphaWrite={true}
          renderOrder={renderOrder}
          userData={userData}
          color={lineColor}
          linewidth={lineWidth}
          dashed={type !== 'SOLID'}
          dashSize={1}
          gapSize={0.1}
          dashOffset={dashOffset}
        />
      )}

      {active && (
        <group ref={lineAndControls}>
          <Line
            name={name + '--line-helper'}
            points={[aPoint, ...angledPathPoints, zPoint]}
            alphaWrite={true}
            renderOrder={renderOrder}
            userData={userData}
            color={lineColor}
            linewidth={lineWidth}
          />

          <LinkTransformController
            points={angledPathPoints}
            mapControls={mapControls}
            onChange={onChangeAnglePoints}
          />

          <PointDot
            position={pointDotPosition}
            visible={pointDotVisible}
            onClick={handleAddPointToLink}
          />
        </group>
      )}

      {zoom >= 35 && showTraffic && <Traffics path={RoundedLinkPath} traffics={traffics} />}
    </group>
  )
}
