import { Plane } from '@react-three/drei'
import React, { forwardRef } from 'react'
import { Color, ColorRepresentation } from 'three'
import { useNetworkDiagram } from '../../contexts'
import { IModelProps } from '../node/Model'
import PlaneWithName from './plane/PlaneWithName'
import PlaneWithoutName from './plane/PlaneWithoutName'

export type PlaneProps = {
  rotation?: THREE.Vector3Tuple
  scale?: THREE.Vector3Tuple
  color?: ColorRepresentation
  name?: string
  userData?: { [key: string]: any }
}

const Model = forwardRef((props: IModelProps, ref: any) => {
  const { name, userData, rotation = [0, 0, 0], scale = [1, 1, 1] } = props
  const { context } = useNetworkDiagram()
  const [isEditing, setIsEditing] = React.useState(false)

  React.useEffect(() => {
    setIsEditing((_) => {
      return typeof context.selected === 'string'
        ? context.selected === userData!.parent
        : context.selected?.userData.parent === userData!.parent
    })
  }, [context.selected])

  return (
    <group>
      <group
        scale={scale}
        rotation={rotation}
        name='NETWORK-DIAGRAM-NODE-MODEL'
      >
        <Plane
          args={[1, 1, 1, 1]}
          scale={[4, 11.5, 1]}
          rotation={[-Math.PI / 2, 0, 0]}
          position={[0, -0.1, 0]}
          material-color={new Color(0xffff00).convertSRGBToLinear()}
          material-transparent={true}
          material-opacity={0.2}
          visible={isEditing}
        />
      </group>
      <group ref={ref}>
        {userData?.showLabel !== false && (
          <PlaneWithName
            name={name}
            scale={scale}
            rotation={rotation}
            color={props.color}
            userData={userData}
          />
        )}
        {props.userData?.showLabel === false && (
          <PlaneWithoutName
            scale={scale}
            rotation={rotation}
            color={props.color}
            userData={userData}
          />
        )}
      </group>
    </group>
  )
})

export default Model
