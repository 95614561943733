import React, { useEffect, useState } from 'react'
import { CurvePath, Vector3 } from 'three'

import { Marker } from './Marker'

export type Traffic = {
  id: string | number
  color: string
}

type TrafficsProps = {
  path: CurvePath<Vector3>
  traffics?: Traffic[]
}

export const Traffics = ({ path, traffics = [] }: TrafficsProps) => {
  const [markers, setMarkers] = useState<any[]>([])

  useEffect(() => {
    const markers = traffics.map((traffic, index) => {
      return (
        <Marker
          key={traffic.id}
          path={path}
          delay={index * 2}
          color={traffic.color}
        />
      )
    })
    setMarkers(markers)
    return () => {
      setMarkers([])
    }
  }, [path, traffics])

  return <group>{markers}</group>
}
