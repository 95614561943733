import React, { forwardRef } from 'react'
import { Baekseok } from './Baekseok'
import { Yeouido } from './Yeouido'
import { LGUPlus } from './LGUPlus'
import { Satellite } from './Satellite'
import { GroundWave } from './GroundWave'
import { PP } from './PP'
import { CUG } from './CUG'
import { AWS } from './AWS'

declare type IOneview3DLettersProps = {
  name:
    | ('여의도' | '백석' | 'LGU+' | '지상파' | 'PP' | 'CUG' | '위성' | 'AWS')
    | string
  userData: { [key: string]: any }
}

export const Oneview3DLetters = forwardRef(
  ({ name, userData }: IOneview3DLettersProps, ref: any) => {
    return (
      <group
        ref={ref}
        rotation={[0, Math.PI / 2, 0]}
        name='NETWORK-DIAGRAM-SHAPES-TEXT3D'
        userData={userData}
      >
        {name === '여의도' && <Yeouido />}
        {name === '백석' && <Baekseok />}
        {name === 'LGU+' && <LGUPlus />}
        {name === '지상파' && <GroundWave />}
        {name === 'PP' && <PP />}
        {name === 'CUG' && <CUG />}
        {name === '위성' && <Satellite />}
        {name === 'AWS' && <AWS />}
      </group>
    )
  }
)
