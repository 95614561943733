import { Box, Line } from '@react-three/drei'
import { useFrame, useThree } from '@react-three/fiber'
import React, { useEffect, useRef, useState } from 'react'
import { BackSide, Box3, BoxGeometry, Raycaster, Vector3 } from 'three'
import { useNetworkDiagram } from '../../contexts'
import { getNode } from '../../utils/nodeUtils'

export const AddLinkControls = () => {
  const { setContext, addLink } = useNetworkDiagram()

  const { camera, mouse, scene } = useThree()
  const [raycaster, setRaycaster] = useState<Raycaster | null>(null)
  const ref = useRef<THREE.Group>(undefined!)
  const lineRef = useRef<any>(undefined!)

  const [aNode, setANode] = useState<THREE.Object3D | null>(null)
  // const [zNode, setZNode] = useState<THREE.Object3D | null>(null)
  const [aPosition, setAPosition] = useState<Vector3 | null>(null)
  const [zPosition, setZPosition] = useState<Vector3 | null>(null)

  const [focused, setFocused] = useState<any>(null)
  const [focusedBox, setFocusedBox] = useState<THREE.BoxGeometry | null>(null)

  useEffect(() => {
    setRaycaster(() => {
      return new Raycaster()
    })
  }, [])

  useFrame((_, delta) => {
    if (lineRef.current) {
      try {
        lineRef.current.material.uniforms.dashOffset.value -= delta * 3
      } catch (error) {
        console.log(error)
      }
    }

    raycaster?.setFromCamera(mouse, camera)
    const intersects = raycaster?.intersectObjects(
      scene.getObjectByName('network-diagram-nodes')?.children || [],
      true
    )

    if (intersects && intersects.length > 0) {
      // setMousePosition(intersects[0].point)
      const node = getNode(intersects[0].object)
      setFocused(node)

      if (node) {
        setZPosition(node.position.clone())
        const model = node.getObjectByName('NETWORK-DIAGRAM-NODE-MODEL')
        if (model) {
          const box = new Box3().setFromObject(model)
          const geometry = new BoxGeometry(
            (box.max.x - box.min.x) * 1.15,
            (box.max.y - box.min.y) * 1.15,
            (box.max.z - box.min.z) * 1.15
          )
          geometry.computeBoundingBox()
          setFocusedBox(geometry)
        }
      }
    } else {
      setFocusedBox(null)
    }
  })

  const handleAddPoint = () => {
    if (aNode) {
      setContext({ enableAddLinkControls: false })
      requestAnimationFrame(() => {
        addLink('new link', aNode.uuid, focused.uuid)
      })
    } else {
      setANode(focused)
      setAPosition(focused.position.clone())
    }
  }

  return (
    <group ref={ref}>
      {aPosition && zPosition && (
        <Line
          ref={lineRef}
          name={'NEW-LINE'}
          points={[aPosition, zPosition]}
          alphaWrite={true}
          color={'#f00'}
          linewidth={2}
          dashed={true}
          dashSize={0.1}
          gapSize={0.1}
        />
      )}

      {focusedBox && (
        <Box
          position={[
            focused.position.x,
            focused.position.y +
              (focusedBox.boundingBox!.max.y - focusedBox.boundingBox!.min.y) /
                2 /
                1.15,
            focused.position.z
          ]}
          geometry={focusedBox}
          onPointerDown={handleAddPoint}
          // onClick={() => {
          // console.log('click')
          // }}
        >
          <meshBasicMaterial
            attach='material'
            color={'#ff0'}
            side={BackSide}
            depthTest={false}
            depthWrite={false}
            transparent
            opacity={0.3}
            wireframe={false}
            wireframeLinewidth={1}
          />
        </Box>
      )}
    </group>
  )
}
