import { createContext, useContext } from 'react'
import { ColorRepresentation, Vector2 } from 'three'
import { LinkProps, NodeProps, ViewportData } from '../components'
import { Traffic } from '../components/link/Traffics'
import { TNodeTypes } from '../components/node/NodeTypes'

export type NetworkDiagramState = {
  theme?: 'light' | 'dark'
  editable?: boolean
  linkMarkerColors?: ColorRepresentation[]
  transform?: 'translate' | 'rotate' | 'scale'
  transforming?: boolean
  enabledMapControls?: boolean
  selected?: any
  linkEdit?: boolean
  enableAddLinkControls?: boolean
  mousePosition?: Vector2
  autoRotate?: boolean
  autoRotateSpeed?: number
  data?: ViewportData
  frameloop?: 'always' | 'demand' | 'never'
  state?: 'CLEAN' | 'DIRTY' | 'NEW'
} // & { [key: string]: any }

export const NetworkDiagramInitialState: NetworkDiagramState = {
  theme: 'light',
  editable: false,
  linkMarkerColors: [],
  transform: 'rotate',
  transforming: false,
  linkEdit: false,
  enabledMapControls: false,
  enableAddLinkControls: false,
  mousePosition: new Vector2(Infinity, Infinity),
  autoRotate: false,
  autoRotateSpeed: 0.2,
  data: {},
  frameloop: 'always',
  state: 'CLEAN'
}

export const NetworkDiagramDefaultContext = {
  /**
   * 다이어그램의 전역상태
   */
  context: NetworkDiagramInitialState,

  /**
   * 다이어그램의 전역상태를 업데이트 한다.
   * @param context
   */
  setContext: (context: NetworkDiagramState) => {
    process.env.NODE_ENV === 'development' &&
      console.log('"setContext" is not implemented', context)
  },

  /**
   * 다이어그램 목록을 연다.
   */
  openFile: () => {
    process.env.NODE_ENV === 'development' &&
      console.log('"openFile" is not implemented')
  },

  /**
   *
   */
  setFile: (data: ViewportData) => {
    process.env.NODE_ENV === 'development' &&
      console.log('"setFile" is not implemented', data)
  },

  /**
   * 새로운 다이어그램을 생성한다.
   * `saveDiagram()`을 호출하기 전에는 데이터를 저장하지 않는다.
   * @param name 다이어그램 이름
   */
  createDiagram: (name: string) => {
    process.env.NODE_ENV === 'development' &&
      console.log('"createDiagram" is not implemented', name)
  },

  /**
   * 다이어그램을 저장한다.
   * @returns
   */
  saveDiagram: () =>
    new Promise<ViewportData>(() => {
      process.env.NODE_ENV === 'development' &&
        console.log('"saveDiagram" is not implemented')
    }),

  /**
   * 다이어그램을 다른이름으로 저장한다.
   * @param name
   * @returns
   */
  saveDiagramAsNew: (name: string) =>
    new Promise<ViewportData>(() => {
      process.env.NODE_ENV === 'development' &&
        console.log('"saveDiagramAsNew" is not implemented', name)
    }),

  /**
   * 다이어그램을 삭제한다.
   * @returns
   */
  deleteDiagram: () =>
    new Promise<ViewportData>(() => {
      process.env.NODE_ENV === 'development' &&
        console.log('"deleteDiagram" is not implemented')
    }),

  /**
   * 노드를 추가한다.
   * @param name 노드 이름
   * @param type  노드 타입
   * @param subtype 노드의 서브 타입
   */
  addNode: (name: string, type: TNodeTypes, subtype: string | undefined) => {
    process.env.NODE_ENV === 'development' &&
      console.log('"addNode" is not implemented', name, type, subtype)
  },

  /**
   * 노드의 정보를 업데이트 한다.
   * @param node 노드 정보
   */
  saveNode: (node: NodeProps) => {
    process.env.NODE_ENV === 'development' &&
      console.log('"saveNode" is not implemented', node)
  },

  // --------------------------------------------------------------------------------
  /**
   * 노드를 추가한다.
   * @param name 링크 이름
   * @param from 링크 시작 노드 아이디
   * @param to   링크 끝 노드 아이디
   */
  addLink: (name: string, from: string, to: string) => {
    process.env.NODE_ENV === 'development' &&
      console.log('"addLink" is not implemented', name, from, to)
  },

  /**
   * 링크의 정보를 업데이트한다.
   * @param link 링크 정보
   */
  saveLink: (link: LinkProps) => {
    process.env.NODE_ENV === 'development' &&
      console.log('"saveLink" is not implemented', link)
  },

  /**
   * 선택한 요소를 삭제한다.
   *
   * @param selected 선택된 요소
   */
  deleteSelected: (selected: any) => {
    process.env.NODE_ENV === 'development' &&
      console.log('"deleteSelected" is not implemented', selected)
  },

  /**
   * 노드에 알람을 추가한다
   * @param id 노드 아이디
   * @param alarm 알람 정보
   */
  addAlarmToNode: (
    id: string,
    alarm: 'power' | 'powers' | 'temperature' | 'service' | 'breakdown'
  ) => {
    process.env.NODE_ENV === 'development' &&
      console.log('"addAlarmOnNode" is not implemented', id, alarm)
  },

  /**
   * 노드에서 알람을 제거한다.
   * @param id 노드 아이디
   * @param alarm 알람 정보
   */
  removeAlarmFromNode: (id: string) => {
    process.env.NODE_ENV === 'development' &&
      console.log('"removeAlarmFromNode" is not implemented', id)
  },

  /**
   * 링크에 트래픽을 추가한다.
   * @param id 링크 아이디
   * @param traffic 트래픽 정보
   */
  addTrafficToLink: (id: string, traffic: Traffic) => {
    process.env.NODE_ENV === 'development' &&
      console.log('"addTrafficToLink" is not implemented', id, traffic)
  },

  /**
   * 링크에서 트래픽을 제거한다.
   * @param id 링크 아이디
   * @param traffic 트래픽 정보
   */
  removeTrafficFromLink: (id: string, traffic: Traffic) => {
    process.env.NODE_ENV === 'development' &&
      console.log('"removeTrafficFromLink" is not implemented', id, traffic)
  },

  /**
   * 링크의 모든 틀래픽을 제거한다.
   * @param id Link ID
   */
  clearTrafficsFromLink: (id: string) => {
    process.env.NODE_ENV === 'development' &&
      console.log('"clearTrafficsFromLink" is not implemented', id)
  },

  /**
   * 링크의 색을 업데이트한다.
   * @param id 링크 아이디
   * @param color 링크 컬러
   */
  setLinkColor: (id: string, color: ColorRepresentation) => {
    process.env.NODE_ENV === 'development' &&
      console.log('"setLinkColor" is not implemented', id, color)
  },

  /**
   *
   * @param frameloop - 'always', 'demand', 'never' is available. However, 'demand' is not working properly. Default is 'always'.
   *  - 'always' : The frameloop is always running.
   *  - 'demand' : The frameloop is running only when the viewport is moving. This may be not working properly. The line traffic is always rendered.
   *  - 'never'  : The frameloop is not running.
   */
  setFrameLoop: (
    frameloop: 'always' | 'demand' | 'never' | undefined = 'always'
  ) => {
    process.env.NODE_ENV === 'development' &&
      console.log('"setFrameLoop()" is not implemented', frameloop)
  },

  /**
   * 편집 내용을 이전 상태로 되돌린다.
   */
  undo: () => {
    process.env.NODE_ENV === 'development' &&
      console.log('"undo()" is not implemented')
  },

  /**
   * 편집 내용을 다음 상태로 되돌린다.
   */
  redo: () => {
    process.env.NODE_ENV === 'development' &&
      console.log('"redo()" is not implemented')
  },

  setDescriptionToNodeByName: (name: string, description: string) => {
    process.env.NODE_ENV === 'development' &&
      console.log(
        '"setDescriptionToNodeByName()" is not implemented',
        name,
        description
      )
  }
}

export type TNetworkDiagramContext = typeof NetworkDiagramDefaultContext

export const NetworkDiagramContext = createContext(NetworkDiagramDefaultContext)

export const useNetworkDiagram = () =>
  useContext<TNetworkDiagramContext>(NetworkDiagramContext)
