import { Menu, MenuButton, MenuDivider, MenuItem } from '@szhsin/react-menu'
import '@szhsin/react-menu/dist/index.css'
import React, { Fragment, useState } from 'react'
import { useNetworkDiagram } from '../../../contexts'
import { CreateNewDialog } from './file/CreateNewDialog'
import { DeleteDialog } from './file/DeleteDialog'
import { OpenFileDialog } from './file/OpenFileDialog'
import { SaveAsNewNameDialog } from './file/SaveAsNewNameDialog'
import { SaveDirtiesBeforeCloseDialog } from './file/SaveDirtiesBeforeCloseDialog'

export default function FileMenu() {
  const {
    context,
    openFile,
    createDiagram,
    deleteDiagram,
    saveDiagram,
    saveDiagramAsNew
  } = useNetworkDiagram()

  const [openedDialog, setOpenedDialog] = useState<
    'new' | 'open' | 'copy' | 'delete' | 'dirty' | null
  >(null)

  // OPEN -------------------------------
  const handleOpenFile = () => {
    // setOpenedDialog('open')
    openFile()
  }

  // NEW -------------------------------
  const handleCreateNewFile = () => {
    setOpenedDialog('new')
  }

  // SAVE -------------------------------
  const handleSaveFile = () => {
    saveDiagram()
      .then(() => {
        //console.log('saveDiagram success')
      })
      .catch((reason) => console.log(reason))
  }

  // SAVE AS NEW -------------------------------
  const handleSaveAsNewName = () => {
    setOpenedDialog('copy')
  }

  // DELETE -------------------------------

  const handleDeleteFile = () => {
    setOpenedDialog('delete')
  }

  // CLOSE -------------------------------
  const handleCloseFile = () => {
    if (context.state === 'NEW' || context.state === 'DIRTY') {
      setOpenedDialog('dirty')
    } else {
      closeDialog()
      gotoHome()
    }
  }

  const gotoHome = () => {
    // TODO  goto editor home
    // 네트워크 다이어그램(토폴로지)를 선택할 수 있는 화면으로 이동
  }

  // CLOSE DIALOG -------------------------------
  const closeDialog = () => {
    requestAnimationFrame(() => {
      setOpenedDialog(null)
    })
  }

  // HANDLERS -------------------------------
  // CREATE NEW FILE NOT SAVE
  const handleOnSubmitCreateNewDialog = (filename: string) => {
    createDiagram(filename)
    closeDialog()
  }
  const handleOnCloseCreateNewDialog = () => {
    closeDialog()
  }

  // SAVE AS NEW
  const handleOnSubmitSaveAsNewNameDialog = (filename: string) => {
    saveDiagramAsNew(filename)
      .then(() => {
        // console.log('saveDiagramAsNew success')
        closeDialog()
      })
      .catch((reason) => console.log(reason))
  }
  const handleOnCloseSaveAsNewNameDialog = () => {
    closeDialog()
  }

  // DELETE
  const handleOnSubmitDeleteDialog = () => {
    deleteDiagram()
      .then(() => {
        // console.log('deleteDiagram success')
        closeDialog()
        gotoHome()
      })
      .catch((reason) => console.log(reason))
  }
  const handleOnCloseDeleteDialog = () => {
    closeDialog()
  }

  // SAVE DIRTY BEFORE CLOSE
  const handleOnCloseAfterSave = () => {
    saveDiagram()
      .then(() => {
        // console.log('saveDiagram success')
        closeDialog()
        gotoHome()
      })
      .catch((reason) => console.log(reason))
  }
  const handleOnCloseWithoutSave = () => {
    closeDialog()
  }

  // OPEN FILE
  const handleOnSubmitOpenFileDialog = () => {
    closeDialog()
  }
  const handleOnCloseOpenFileDialog = () => {
    closeDialog()
  }

  return (
    <Fragment>
      <Menu
        menuClassName={'appbar-menu'}
        menuButton={
          <MenuButton
            style={{
              background: 'transparent',
              color: context.theme === 'dark' ? '#fff' : '#000',
              border: 0,
              marginLeft: '24px'
            }}
          >
            파일
          </MenuButton>
        }
        theming={'dark'}
      >
        <MenuItem onClick={handleCreateNewFile}>새 파일</MenuItem>
        <MenuItem onClick={handleOpenFile}>열기...</MenuItem>
        <MenuDivider />
        <MenuItem
          disabled={
            // context.data?.readonly === true ||
            context.state === undefined || context.state === 'CLEAN'
          }
          onClick={handleSaveFile}
        >
          저장
        </MenuItem>
        <MenuItem onClick={handleSaveAsNewName}>다른 이름으로 저장...</MenuItem>
        <MenuDivider />
        <MenuItem
          disabled={context.data?.readonly === true}
          onClick={handleDeleteFile}
        >
          삭제
        </MenuItem>
        <MenuItem onClick={handleCloseFile}>닫기</MenuItem>
      </Menu>

      {/* 
        DIALOGS -------------------------------------------------- 
      */}

      <OpenFileDialog
        isOpen={openedDialog === 'open'}
        onSubmit={handleOnSubmitOpenFileDialog}
        onClose={handleOnCloseOpenFileDialog}
      />

      <CreateNewDialog
        isOpen={openedDialog === 'new'}
        onSubmit={handleOnSubmitCreateNewDialog}
        onClose={handleOnCloseCreateNewDialog}
      />

      <SaveAsNewNameDialog
        isOpen={openedDialog === 'copy'}
        onSubmit={handleOnSubmitSaveAsNewNameDialog}
        onClose={handleOnCloseSaveAsNewNameDialog}
      />

      <DeleteDialog
        isOpen={openedDialog === 'delete'}
        onSubmit={handleOnSubmitDeleteDialog}
        onClose={handleOnCloseDeleteDialog}
      />

      <SaveDirtiesBeforeCloseDialog
        isOpen={openedDialog === 'dirty'}
        onCloseAfterSave={handleOnCloseAfterSave}
        onCloseWithoutSave={handleOnCloseWithoutSave}
      />
    </Fragment>
  )
}
