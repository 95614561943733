import React from 'react'
import * as THREE from 'three'
import { ColorRepresentation } from 'three'
import { Text as TextImpl } from '@react-three/drei'
import { GroupProps } from '@react-three/fiber'

declare type TextProps = GroupProps & {
  fontWeight?: 'Thin' | 'Light' | 'Medium' | 'Regular' | 'Bold'
  color?: ColorRepresentation
  fontSize?: number
  anchorX?: number | 'center' | 'left' | 'right' | undefined
  anchorY?:
    | number
    | 'middle'
    | 'top'
    | 'bottom'
    | 'top-baseline'
    | 'bottom-baseline'
    | undefined
}

export const Text = React.forwardRef<THREE.Object3D<THREE.Event>, TextProps>(
  (
    {
      position,
      rotation,
      children,
      color = '#ffffff',
      fontSize = 1,
      fontWeight = 'Light',
      anchorX = 'center',
      anchorY = 'middle'
    }: TextProps,
    ref
  ) => {
    const textRef = React.useRef<any>()
    const [fontName, setFontName] = React.useState<string>(
      'assets/fonts/KTfontLight.ttf'
    )

    React.useEffect(() => {
      switch (fontWeight) {
        case 'Thin':
          setFontName('assets/fonts/KTfontThin.ttf')
          break
        case 'Light':
          setFontName('assets/fonts/KTfontLight.ttf')
          break
        case 'Medium':
        case 'Regular':
          setFontName('assets/fonts/KTfontMedium.ttf')
        case 'Bold':
          setFontName('assets/fonts/KTfontBold.ttf')
          break
        default:
          setFontName('assets/fonts/KTfontLight.ttf')
          break
      }
    }, [fontWeight])

    return (
      <group
        ref={ref}
        name='NETWORK-DIAGRAM-SHAPE--TEXT'
        position={position}
        rotation={rotation}
      >
        <TextImpl
          ref={textRef}
          color={color}
          font={`${process.env.REACT_APP_BASE_URL || ''}${fontName}`}
          fontSize={fontSize}
          material-side={THREE.DoubleSide}
          rotation={[-Math.PI / 2, 0, Math.PI / 2]}
          // outlineColor={'#000000'}
          // outlineWidth={'1%'}
          // outlineBlur={'0%'}
          // outlineOpacity={1}
          anchorX={anchorX}
          anchorY={anchorY}
        >
          {children}
        </TextImpl>
      </group>
    )
  }
)
