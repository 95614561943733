import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import React from 'react'

type Props = {
  isOpen: boolean
  onCloseAfterSave: () => void
  onCloseWithoutSave: () => void
}

export const SaveDirtiesBeforeCloseDialog = ({
  isOpen,
  onCloseAfterSave,
  onCloseWithoutSave
}: Props) => {
  const handleCloseAfterSave = () => {
    onCloseAfterSave && onCloseAfterSave()
  }

  const handleCloseWithoutSave = () => {
    onCloseWithoutSave && onCloseWithoutSave()
  }

  return (
    <Dialog
      open={isOpen}
      onClose={handleCloseWithoutSave}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>저장하시겠습니까?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          저장하지 않은 변경사항이 있습니다.
        </DialogContentText>
        <DialogContentText>
          변경사항을 저장하려면 [저장하고 닫기]를 눌러주세요.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseWithoutSave}>저장하지 않고 닫기</Button>
        <Button onClick={handleCloseAfterSave} autoFocus>
          저장하고 닫기
        </Button>
      </DialogActions>
    </Dialog>
  )
}
