import { CurvePath, QuadraticBezierCurve3, Vector, Vector3 } from 'three'

export function pointsOnRoundedCornerLine(
  points: Vector3[],
  radius: number = 0.3,
  smoothness: number = 32,
  closed: boolean = false
) {
  if (points === undefined) {
    // process.env.NODE_ENV === 'development' &&
    //   console.log("RoundedCornerLine: 'points' is undefined")
    return []
  }

  if (points.length < 3) {
    // process.env.NODE_ENV === 'development' &&
    //   console.log(
    //     "RoundedCornerLine: 'points' has insufficient length (should be equal or greater than 3)"
    //   )
    return points
  }

  // minimal segment
  let minVector = new Vector3()
  let minLength = minVector.subVectors(points[0], points[1]).length()
  for (let i = 1; i < points.length - 1; i++) {
    minLength = Math.min(
      minLength,
      minVector.subVectors(points[i], points[i + 1]).length()
    )
  }
  if (closed) {
    minLength = Math.min(
      minLength,
      minVector.subVectors(points[points.length - 1], points[0]).length()
    )
  }

  // radius can't be greater than a half of a minimal segment
  radius = radius > minLength * 0.5 ? minLength * 0.5 : radius

  let startIndex = 1
  let endIndex = points.length - 2
  if (closed) {
    startIndex = 0
    endIndex = points.length - 1
  }

  let curvePath = new CurvePath()

  for (let i = startIndex; i <= endIndex; i++) {
    let iStart = i - 1 < 0 ? points.length - 1 : i - 1
    let iMid = i
    let iEnd = i + 1 > points.length - 1 ? 0 : i + 1
    let pStart = points[iStart]
    let pMid = points[iMid]
    let pEnd = points[iEnd]

    // key points
    let keyStart = new Vector3().subVectors(pStart, pMid).normalize()
    let keyMid = pMid
    let keyEnd = new Vector3().subVectors(pEnd, pMid).normalize()

    let halfAngle = keyStart.angleTo(keyEnd) * 0.5

    let keyLength = radius / Math.tan(halfAngle)

    keyStart.multiplyScalar(keyLength).add(keyMid)
    keyEnd.multiplyScalar(keyLength).add(keyMid)

    curvePath.add(new QuadraticBezierCurve3(keyStart, keyMid, keyEnd))
  }

  let curvePoints: Vector3[] = curvePath
    .getPoints(smoothness)
    .map((v: Vector) => v as Vector3)

  // NaN, Infinity, -Infinity가 있으면 제거
  curvePoints = curvePoints.filter(
    (v) => isFinite(v.x) && isFinite(v.y) && isFinite(v.z)
  )

  let fullPoints: Vector3[] = []

  if (!closed) {
    fullPoints.push(points[0])
  }
  fullPoints = fullPoints.concat(curvePoints)
  if (!closed) {
    fullPoints.push(points[points.length - 1])
  } else {
    fullPoints.push(fullPoints[0].clone())
  }

  return fullPoints
}
