import { Object3D, Raycaster, Scene, Vector3 } from 'three'
// import { linkY } from '../components/config'

/**
 * 링크에서 시작, 끝 장비를 찾아서 반환한다.
 * @param scene
 * @param id 네트워크 장비의 ID (UUID)
 * @returns
 */
export function getNodeUUID(scene: Scene, id: string) {
  return scene.getObjectByProperty('uuid', id) as Object3D
}

/**
 * 디자이너에서 네트워크 장비를 마우스로 클릭하면 선택하는 기능을 제공합니다.
 * @param node
 * @returns Node Model
 */
export function getNode(node: Object3D | undefined): Object3D | undefined {
  if (!node) return

  if (node.name === 'NETWORK-DIAGRAM-NODE') {
    return node
    // 링크 컨트롤러 자체적으로 해결한다
    // } else if (node.name === 'LINK_HANDLER') {
    //   return node
  } else if (node.name === 'NETWORK-DIAGRAM-CHART') {
    return node
  } else if (node.name === 'NETWORK-DIAGRAM-SHAPE') {
    return node
  }

  if (node.parent) {
    return getNode(node.parent)
  }

  if (node instanceof Scene) {
    return node.getObjectByProperty('uuid', node.userData.parent)
  }

  return
}

export function getNodeModel(node: Object3D | undefined): Object3D | undefined {
  if (!node) return

  if (node.name === 'NETWORK-DIAGRAM-NODE-MODEL') {
    return node
  }

  if (node.parent) {
    return getNodeModel(node.parent)
  }

  return
}

const raycaster = new Raycaster()
const direction = new Vector3()

export function getPointOnNodeFace(node: Object3D, origin: Vector3) {
  const targetPosition = node.position.clone()

  direction.subVectors(targetPosition, origin).normalize()
  raycaster.set(origin, direction)

  const intersects = raycaster.intersectObjects(node.children, true)
  const intersect = intersects.find((i) => i.object.type === 'Mesh')

  // 노드 표면의 점을 찾지 못하면 중심점을 반환한다.
  // return intersect?.point.clone().setY(linkY) || targetPosition
  return intersect?.point.clone() || targetPosition
}
