import { useFrame } from '@react-three/fiber'
import React, { forwardRef, useEffect, useState } from 'react'

import {
  DoubleSide,
  ExtrudeBufferGeometry,
  MeshStandardMaterial,
  Path,
  ShapeGeometry,
  ShapePath
} from 'three'
import { degreeToRadian } from '../../../utils/d3threeD'

type ArcProps = {
  aX?: number // 중심점 x
  aY?: number // 중심점 y
  aRadius?: number // 외부 반지름
  aRadius2?: number // 내부 반지름
  aStartAngle?: number // 시작 각도
  aEndAngle?: number // 종료 각도
  aClockwise?: boolean // true: 시계방향, false: 반시계방향
  color?: THREE.ColorRepresentation // 색상
  animate?: boolean // true: 애니메이션 적용
  clippings?: any
  opacity?: number
  visible?: boolean
  extrude?: number
}

export const Arc = forwardRef(
  (
    {
      aX = 0,
      aY = 0,
      aRadius = 1,
      aRadius2 = 0.8,
      aStartAngle = 0,
      aEndAngle = 90,
      aClockwise = true,
      color = '#3377ff',
      animate = false,
      opacity = 1,
      visible = true,
      extrude = 0
    }: ArcProps,
    ref: any
  ) => {
    const [geometry, setGeometry] = useState<ShapeGeometry>(
      ArcGeometry(
        aX,
        aY,
        aRadius,
        aRadius2,
        aStartAngle,
        aEndAngle,
        aClockwise,
        extrude
      )
    )
    const [material] = useState(
      new MeshStandardMaterial({
        color: color,
        side: DoubleSide,
        transparent: true,
        opacity: opacity,
        visible: visible
      })
    )

    useEffect(() => {
      geometry?.dispose()
      setGeometry(
        ArcGeometry(
          aX,
          aY,
          aRadius,
          aRadius2,
          aStartAngle,
          aEndAngle,
          aClockwise,
          extrude
        )
      )
      return () => {
        geometry?.dispose()
      }
    }, [aX, aY, aRadius, aRadius2, aStartAngle, aEndAngle, aClockwise, extrude])

    useFrame(({ clock }) => {
      if (animate !== false) {
        const t = clock.getElapsedTime()
        const angle = (aEndAngle * t) % aEndAngle
        geometry?.dispose()
        setGeometry(
          ArcGeometry(
            aX,
            aY,
            aRadius,
            aRadius2,
            aStartAngle,
            angle,
            aClockwise,
            extrude
          )
        )
      }
    })

    return <mesh ref={ref} geometry={geometry} material={material} />
  }
)

export const ArcGeometry = (
  aX = 0,
  aY = 0,
  aRadius = 1,
  aRadius2 = 0.8,
  aStartAngle = 0,
  aEndAngle = 90,
  aClockwise = true,
  extrude = 0,
  bevelEnabled = false
) => {
  // console.log('create ArcGeometry', performance.now())

  const aSA = degreeToRadian(aStartAngle)
  const aEA = degreeToRadian(aEndAngle)

  const subpath = new Path()

  // 외부 원호
  subpath.absarc(aX, aY, aRadius, aSA, aEA, aClockwise)

  // 내부 원호
  subpath.absarc(aX, aY, aRadius2, aEA, aSA, !aClockwise)

  const path = new ShapePath()
  path.subPaths.push(subpath)
  const shape = path.toShapes(true)
  if (extrude > 0) {
    return new ExtrudeBufferGeometry(shape, {
      depth: extrude,
      bevelEnabled: bevelEnabled,
      bevelThickness: 0.01,
      bevelSize: 0.01,
      bevelSegments: 1,
      bevelOffset: 0
    })
  }
  return new ShapeGeometry(shape)
}
