import React from 'react'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'

import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'

type Props = {
  isOpen: boolean
  onSubmit?: () => void
  onClose: () => void
}

export const OpenFileDialog = ({ isOpen, onSubmit, onClose }: Props) => {
  const handleSubmit = () => {
    onSubmit && onSubmit()
  }

  const handleClose = () => {
    onClose && onClose()
  }

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle id='alert-dialog-title'>파일 열기</DialogTitle>

      <DialogContent>
        <BasicTable />
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>취소</Button>
        <Button onClick={handleSubmit} autoFocus>
          파일 열기
        </Button>
      </DialogActions>
    </Dialog>
  )
}

function createData(
  name: string,
  createdAt: string,
  isPublished: boolean,
  publishedAt: string
) {
  return { name, createdAt, isPublished, publishedAt }
}

const rows = [
  createData('원뷰', '24/01/2020', true, '2022/01/01'),
  createData('여의도', '24/01/2020', true, '2022/01/01'),
  createData('백석', '24/01/2020', true, '2022/01/01'),
  createData('TOPOLOGY 4', '24/01/2020', true, '2022/01/01'),
  createData('TOPOLOGY 5', '24/01/2020', true, '2022/01/01'),
  createData('TOPOLOGY 6', '24/01/2020', true, '2022/01/01'),
  createData('TOPOLOGY 7', '24/01/2020', true, '2022/01/01'),
  createData('TOPOLOGY 8', '24/01/2020', true, '2022/01/01'),
  createData('TOPOLOGY 9', '24/01/2020', true, '2022/01/01'),
  createData('TOPOLOGY 10', '24/01/2020', false, '2020/10/10'),
  createData('TOPOLOGY 11', '24/01/2020', false, '2020/10/10'),
  createData('TOPOLOGY 12', '24/01/2020', false, '2020/10/10'),
  createData('TOPOLOGY 13', '24/01/2020', false, '2020/10/10'),
  createData('TOPOLOGY 14', '24/01/2020', false, '2020/10/10'),
  createData('TOPOLOGY 15', '24/01/2020', false, '2020/10/10'),
  createData('TOPOLOGY 16', '24/01/2020', false, '2020/10/10'),
  createData('TOPOLOGY 17', '24/01/2020', false, '2020/10/10'),
  createData('TOPOLOGY 18', '24/01/2020', false, '2020/10/10'),
  createData('TOPOLOGY 19', '24/01/2020', true, '2020/10/10'),
  createData('TOPOLOGY 20', '24/01/2020', true, '2020/10/10'),
  createData('TOPOLOGY 21', '24/01/2020', true, '2020/10/10'),
  createData('TOPOLOGY 22', '24/01/2020', true, '2020/10/10'),
  createData('TOPOLOGY 23', '24/01/2020', true, '2020/10/10'),
  createData('TOPOLOGY 24', '24/01/2020', true, '2020/10/10'),
  createData('TOPOLOGY 25', '24/01/2020', true, '2020/10/10'),
  createData('TOPOLOGY 26', '24/01/2020', true, '2020/10/10'),
  createData('TOPOLOGY 27', '24/01/2020', true, '2020/10/10'),
  createData('TOPOLOGY 28', '24/01/2020', true, '2020/10/10'),
  createData('TOPOLOGY 29', '24/01/2020', true, '2020/10/10'),
  createData('TOPOLOGY 30', '24/01/2020', true, '2020/10/10')
]

const BasicTable = () => {
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(5)

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <Box component='div' m={2}>
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 300, width: '100%' }}
          size='small'
          aria-label='simple table'
        >
          <TableHead>
            <TableRow>
              <TableCell> </TableCell>
              <TableCell>파일명</TableCell>
              <TableCell align='right'>등록일</TableCell>
              <TableCell align='right'>공개여부</TableCell>
              <TableCell align='right'>공개일</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow
                  key={row.name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component='td'>{index + 1}</TableCell>
                  <TableCell component='th' scope='row'>
                    {row.name}
                  </TableCell>
                  <TableCell align='right'>{row.createdAt}</TableCell>
                  <TableCell align='right'>
                    {row.isPublished ? '공개' : '비공개'}
                  </TableCell>
                  <TableCell align='right'>{row.publishedAt}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        size='small'
        rowsPerPageOptions={[5, 10, 25]}
        component='div'
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  )
}
