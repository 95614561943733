import { Plane, Text } from '@react-three/drei'
import React from 'react'
import { useNetworkDiagram } from '../../contexts'
import { IModelProps } from '../node/Model'

// console.log('REACT_APP_BASE_URL', process.env);

const Model = React.forwardRef(
  (
    { name, scale = [1, 1, 1], rotation = [0, 0, 0], userData }: IModelProps,
    ref: any
  ) => {
    const { context } = useNetworkDiagram()
    const textRef = React.useRef<THREE.Mesh>()
    const backgroundRef = React.useRef<THREE.Mesh>()

    const fontSize = userData?.fontSize ? userData?.fontSize : 1
    const fontColor = userData?.fontColor
      ? userData?.fontColor
      : context.theme === 'dark'
      ? 0xffffff
      : 0x333333
    const fontWeight = userData?.fontWeight ? userData?.fontWeight : 'Light'
    const outlineColor = userData?.labelOutlineColor
      ? userData?.labelOutlineColor
      : context.theme === 'dark'
      ? 0xffffff
      : 0x000000
    const outlineWidth = userData?.labelOutlineWidth
      ? userData?.labelOutlineWidth
      : 0
    const outlineOpacity = userData?.labelOutlineOpacity
      ? userData?.labelOutlineOpacity
      : 0

    React.useEffect(() => {
      if (textRef.current && backgroundRef.current) {
        textRef.current.geometry.computeBoundingBox()
      }
    }, [textRef, backgroundRef])

    return (
      <group
        ref={ref}
        rotation={rotation}
        scale={scale}
        name='NETWORK-DIAGRAM-NODE-MODEL'
      >
        {userData?.background?.visible === true && (
          <Plane
            ref={backgroundRef}
            args={[userData?.background?.width, userData?.background.height]}
            position={[0, -0.55, 0]}
            material-color={
              userData?.background?.color
                ? userData?.background?.color
                : 0xffffff
            }
            material-opacity={
              userData?.background?.opacity ? userData?.background?.opacity : 1
            }
            material-transparent={true}
          />
        )}
        <Text
          ref={textRef}
          color={fontColor}
          fontSize={fontSize}
          rotation={[-Math.PI / 2, 0, 0]}
          maxWidth={20}
          lineHeight={1}
          letterSpacing={0}
          textAlign={'center'}
          font={`${
            process.env.REACT_APP_BASE_URL || ''
          }assets/fonts/KTfont${fontWeight}.ttf`}
          anchorX={'center'}
          anchorY={'middle'}
          material-depthTest={true}
          material-depthWrite={false}
          outlineWidth={`${outlineWidth}%`}
          outlineColor={outlineColor}
          outlineOpacity={outlineOpacity}
          userData={userData}
        >
          {name}
        </Text>
      </group>
    )
  }
)

export default Model
