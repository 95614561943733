import React from 'react'
import Aws from '../elements/Aws'
import Encoder from '../elements/Encoder'
import IPAcoMux from '../elements/IPAcoMux'
import IPMux from '../elements/IpMux'
import L3 from '../elements/L3'
import Mspp from '../elements/Mspp'
import Satellite from '../elements/Satellite'
import Server from '../elements/Server'
import Skylife from '../elements/Skylife'
import Transcoder from '../elements/Transcoder'
import Transmission from '../elements/Transmission'
import Typograpy from '../elements/Typography'
import Unknown from '../elements/Unknown'
import Seezn from '../elements/Seezn'
import Cloud from '../elements/Cloud'

// oneview elements
import Plane from '../elements/Plane'
// import { Baekseok as Text3D_Baekseok } from '../oneview/letters/Baekseok'
// import { Yeouido as Text3D_Yeouido } from '../oneview/letters/Yeouido'
// import { LGUPlus as Text3D_LGUPlus } from '../oneview/letters/LGUPlus'
// import { GroundWave as Text3D_GroundWave } from '../oneview/letters/GroundWave'
// import { PP as Text3D_Pp } from '../oneview/letters/PP'
// import { CUG as Text3D_Cug } from '../oneview/letters/CUG'
// import { Satellite as Text3D_Satellite } from '../oneview/letters/Satellite'
// import { AWS as Text3D_Aws } from '../oneview/letters/AWS'
// import { ChartBottomLeft as Chart3D_Plane } from '../oneview/charts/2d/ChartBottomLeft'
// import { ChartBottomRight1 as Chart3D_Sylinder } from '../oneview/charts/3d/ChartBottomRight1'
// import Chart3D_Block from '../oneview/charts/3d/Block'
// import Chart3D_Switch from '../oneview/charts/3d/Switch'
// import Chart3D_IPAco from '../oneview/charts/3d/IPACO'
// import Chart3D_Mux from '../oneview/charts/3d/IPMUX'
// import Chart3D_Encoder from '../oneview/charts/3d/Encoder'

import Rect from '../elements/Rect'

import { TNodeTypes } from './NodeTypes'

export type IModelProps = {
  name?: string
  type?: TNodeTypes
  scale?: THREE.Vector3Tuple
  position?: THREE.Vector3Tuple
  rotation?: THREE.Vector3Tuple
  color?: THREE.ColorRepresentation
  outlineWidth?: number
  outlineColor?: THREE.ColorRepresentation
  userData?: { [key: string]: any }
}

export const Model = React.forwardRef(
  (
    {
      name,
      userData,
      type = 'Unknown',
      rotation = [0, 0, 0],
      scale = [1, 1, 1],
      color
    }: IModelProps,
    ref
  ) => {
    let NodeModel = Unknown

    // prettier-ignore
    switch (type) {
      case 'Encoder':         NodeModel = Encoder;        break
      case 'MSPP':            NodeModel = Mspp;           break
      case 'IPAcoMux':        NodeModel = IPAcoMux;       break
      case 'L3':              NodeModel = L3;             break
      case 'MUX':             NodeModel = IPMux;          break
      case 'Server':          NodeModel = Server;         break
      case 'Satellite':       NodeModel = Satellite;      break
      case 'Transcoder':      NodeModel = Transcoder;     break
      case 'Transmission':    NodeModel = Transmission;   break
      case 'Seezn':           NodeModel = Seezn;          break
      case 'Skylife':         NodeModel = Skylife;        break
      case 'Aws':             NodeModel = Aws;            break
      case 'Cloud':              NodeModel = Cloud;             break

      // Shapes
      case 'Typography':      NodeModel = Typograpy;      break
      case 'Rect':            NodeModel = Rect;           break

// onveiw elements
      case 'Plane':              NodeModel = Plane;             break
      // case 'TEXT_3D_BAEKSEOK':   NodeModel = Text3D_Baekseok;   break                
      // case 'TEXT_3D_YEOUIDO':    NodeModel = Text3D_Yeouido;    break              
      // case 'TEXT_3D_LGUPLUS':    NodeModel = Text3D_LGUPlus;    break              
      // case 'TEXT_3D_GROUNDWAVE': NodeModel = Text3D_GroundWave; break                 
      // case 'TEXT_3D_PP':         NodeModel = Text3D_Pp;         break         
      // case 'TEXT_3D_CUG':        NodeModel = Text3D_Cug;        break          
      // case 'TEXT_3D_SATELLITE':  NodeModel = Text3D_Satellite;  break                
      // case 'TEXT_3D_AWS':        NodeModel = Text3D_Aws;        break          
      // case 'CHART_3D_PLANE':     NodeModel = Chart3D_Plane;     break             
      // case 'CHART_3D_SYLINDER':  NodeModel = Chart3D_Sylinder;  break                
      // case 'CHART_3D_BLOCK':     NodeModel = Chart3D_Block;     break             
      // case 'CHART_3D_SWITCH':    NodeModel = Chart3D_Switch;    break              
      // case 'CHART_3D_IPACO':     NodeModel = Chart3D_IPAco;     break             
      // case 'CHART_3D_MUX':       NodeModel = Chart3D_Mux;       break           
      // case 'CHART_3D_ENCODER':   NodeModel = Chart3D_Encoder;   break               

// defaults to Unknown
      case 'Unknown':
      default: NodeModel = Unknown;                       break
    }
    return (
      <NodeModel
        ref={ref}
        scale={scale}
        rotation={rotation}
        name={name}
        userData={userData}
        color={color}
      />
    )
  }
)
