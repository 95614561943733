import { Vector3 } from 'three'

/**
 * Check if p is between a and b, or outside
 * @param a Start of the segment
 * @param b End of the segment
 * @param p Point to check
 */
export function isPointOnSegment(a: Vector3, z: Vector3, p: Vector3): boolean {
  const tolerance = 0.01
  const ab = a.distanceTo(z)
  const pa = p.distanceTo(a)
  const pb = p.distanceTo(z)
  const difference = Math.abs(ab - (pa + pb))
  return difference < tolerance && difference > -tolerance
}
