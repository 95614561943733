import { Text } from '@react-three/drei'
import React, {
  // forwardRef,
  useCallback,
  useEffect,
  useState
} from 'react'
import { DoubleSide, ExtrudeGeometry } from 'three'
import { useNetworkDiagram } from '../../../contexts'
import { transformSVGPath } from '../../../utils/d3threeD'
import { PlaneProps } from '../Plane'

const getLabelBox = (labelNode: any) => {
  const startTime = performance.now()
  return new Promise<THREE.Box3>((resolve, reject) => {
    const repeat = () => {
      const box = labelNode.geometry.boundingBox
      if (isFinite(box?.max.x || Infinity)) {
        resolve(box)
        return
      }
      if (performance.now() - startTime > 60000) {
        reject(new Error('timeout'))
        return
      }
      labelNode.geometry.computeBoundingBox()
      requestAnimationFrame(repeat)
    }
    repeat()
  })
}

export const PlaneWithName = //forwardRef(
  ({
    rotation = [0, 0, 0],
    scale = [1, 1, 1],
    color = '#3377ff',
    name = 'Undefined',
    userData
  }: PlaneProps) =>
    // ref: any
    {
      const { context } = useNetworkDiagram()

      const [label, setLabel] = useState<any>(null)
      const [geometry, setGeometry] = useState<THREE.ExtrudeGeometry>(
        undefined!
      )

      useEffect(() => {
        // const _scale = scale0.clone().multiply(new Vector3(...scale))

        if (label) {
          getLabelBox(label)
            .then((box) => {
              const w = box.max.x - box.min.x
              const h = box!.max.y - box!.min.y
              const planePath = `M${
                100 + w * 100
              } 95.54 l-27.31-58.79 A10.005 10.005 0 1 1 ${
                63.62 + w * 100
              } 30.96 H 60.1 c -16.57 0 -30 13.43 -30 30 V ${
                400 * scale[0]
              } c 0 5.52 4.48 10 10 10 h ${
                (h * 100 + 400) * scale[2] * 2.1
              } c 16.57 0 30 -13.43 30 -30 V 105.54 c 0-5.52-4.48-10-10-10 H 400z`

              const shape = transformSVGPath(planePath)
              const geo = new ExtrudeGeometry(shape, {
                steps: 1,
                depth: 0.01,
                bevelEnabled: true,
                bevelThickness: 6,
                bevelSize: 6,
                bevelSegments: 12
              })

              geo.scale(0.01, 0.01, 0.01)
              geo.center()
              setGeometry(geo)
              // ref.current?.scale.set(1, 1, 1)
              // setScale0(_scale)
              const x = -(geo.boundingBox!.max.x - geo.boundingBox!.min.x) / 2
              const y =
                -(geo.boundingBox!.max.y - geo.boundingBox!.min.y) / 2 + h / 2
              const z = (geo.boundingBox!.max.z - geo.boundingBox!.min.z) / 2

              label.position.set(x + w / 2 + 0.25, y, z - 0.125)
              label.rotation.set(-Math.PI, 0, 0)
            })
            .catch((err) => console.error(err))
        }
      }, [label, scale])

      const labelCallbackRef = useCallback<any>((labelNode: any) => {
        if (labelNode) {
          labelNode.geometry.computeBoundingBox()
          setLabel(labelNode)
        }
      }, [])

      return (
        <group rotation={rotation}>
          <group
            position={[0, -0.1, 0]}
            rotation={[Math.PI / 2, 0, -Math.PI / 2]}
          >
            <mesh
              // ref={ref}
              geometry={geometry}
            >
              <meshBasicMaterial
                attach='material'
                color={color}
                transparent={true}
                opacity={0.125}
              ></meshBasicMaterial>
            </mesh>

            <Text
              ref={labelCallbackRef}
              visible={false}
              color={
                userData?.fontColor !== undefined
                  ? userData?.fontColor
                  : context.theme === 'dark'
                  ? 0xffffff
                  : 0x000000
              }
              fontSize={
                userData?.fontSize !== undefined ? userData?.fontSize : 0.8
              }
              font={`${
                process.env.REACT_APP_BASE_URL || ''
              }assets/fonts/KTfont${
                userData?.fontWeight !== undefined
                  ? userData?.fontWeight
                  : 'Light'
              }.ttf`}
              material-side={DoubleSide}
              outlineWidth={
                userData?.labelOutlineWidth !== undefined
                  ? userData?.labelOutlineWidth + '%'
                  : '5%'
              }
              outlineColor={
                userData?.labelOutlineColor !== undefined
                  ? userData?.labelOutlineColor
                  : context.theme === 'dark'
                  ? 0xffffff
                  : 0x000000
              }
              outlineOpacity={
                userData?.labelOutlineOpacity !== undefined
                  ? userData?.labelOutlineOpacity
                  : 1
              }
            >
              {name}
            </Text>
          </group>
        </group>
      )
    }
// )

export default PlaneWithName
