import { Instance, Instances } from '@react-three/drei'
import React from 'react'
import {
  Color,
  DoubleSide,
  ExtrudeGeometry,
  MeshStandardMaterial,
  ShapeGeometry
} from 'three'
import { degreeToRadian } from '../../../../utils/d3threeD'
import { ArcGeometry } from '../Arc'

export interface ClockChartProps {
  value: number
  color: THREE.ColorRepresentation
  children: React.ReactNode
  name: string
}

export const ClockChart = ({
  value = 0,
  color = 0xffffff,
  children,
  name = ''
}: ClockChartProps) => {
  const [markers, geometry, material] = React.useMemo(() => {
    const counts = 40
    const angle = 300 / counts
    const gap = angle / 2
    const dgap = degreeToRadian(gap)
    const markers = Array.from(
      { length: counts },
      (_, i) => degreeToRadian(i * angle) - dgap
    )
    const geometry = ArcGeometry(0, 0, 0.7, 0.5, 0, angle - gap, true, 0, false)
    const material = new MeshStandardMaterial({
      color: new Color(color).convertSRGBToLinear(),
      side: DoubleSide,
      transparent: false,
      opacity: 0.5
    })

    return [markers, geometry, material]
  }, [])

  React.useEffect(() => {
    return () => {
      geometry.dispose()
      material.dispose()
    }
  }, [])

  return (
    <React.Fragment>
      {children}
      <group rotation={[-Math.PI / 2, 0, Math.PI]} name={`ClockChart-${name}`}>
        <Marks
          value={value}
          markers={markers}
          geometry={geometry}
          material={material}
        />
      </group>
    </React.Fragment>
  )
}

export default ClockChart

const Marks = ({
  value = 0,
  markers = [],
  geometry,
  material
}: {
  value: number
  markers: number[]
  geometry: ExtrudeGeometry | ShapeGeometry
  material: MeshStandardMaterial
}) => {
  return (
    <Instances
      range={Math.ceil((value / 100) * markers.length)}
      geometry={geometry}
      material={material}
    >
      {markers.map((mark, i) => (
        <Mark key={i} rotation={mark} />
      ))}
    </Instances>
  )
}

const Mark = ({ rotation = 0 }: { rotation: number }) => {
  const ref = React.useRef<typeof Instance>(null!)
  return <Instance ref={ref} rotation={[0, 0, rotation]} />
}
