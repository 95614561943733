import { useAnimations, useGLTF } from '@react-three/drei'
import React, { forwardRef, useState } from 'react'
import { IModelProps } from '../node/Model'

const modelSourceEtc = '/assets/models/server-etc.gltf'
useGLTF.preload(modelSourceEtc)

const modelSourceCas = '/assets/models/server-cas.gltf'
useGLTF.preload(modelSourceCas)

const modelSourceDvb = '/assets/models/server-dvb.gltf'
useGLTF.preload(modelSourceDvb)

const modelSourceAd = '/assets/models/server-ad.gltf'
useGLTF.preload(modelSourceAd)
const modelSourceAmoc = '/assets/models/server-amoc.gltf'
useGLTF.preload(modelSourceAmoc)
const modelSourceDbs = '/assets/models/server-dbs.gltf'
useGLTF.preload(modelSourceDbs)
const modelSourceGigagenie = '/assets/models/server-gigagenie.gltf'
useGLTF.preload(modelSourceGigagenie)
const modelSourceIcod = '/assets/models/server-icod.gltf'
useGLTF.preload(modelSourceIcod)
const modelSourceIcp = '/assets/models/server-icp.gltf'
useGLTF.preload(modelSourceIcp)
const modelSourceImage = '/assets/models/server-image.gltf'
useGLTF.preload(modelSourceImage)
const modelSourceMein = '/assets/models/server-mein.gltf'
useGLTF.preload(modelSourceMein)
const modelSourceNvod = '/assets/models/server-nvod.gltf'
useGLTF.preload(modelSourceNvod)
const modelSourceObts = '/assets/models/server-obts.gltf'
useGLTF.preload(modelSourceObts)
const modelSourceOms = '/assets/models/server-oms.gltf'
useGLTF.preload(modelSourceOms)

const Model = forwardRef((props: IModelProps, ref: any) => {
  return (
    <group
      ref={ref}
      scale={props.scale}
      rotation={props.rotation}
      name='NETWORK-DIAGRAM-NODE-MODEL'
    >
      {props.userData?.subtype === 'dvb' && <ServerDvb {...props} />}
      {props.userData?.subtype === 'cas' && <ServerCas {...props} />}
      {props.userData?.subtype === 'etc' && <ServerEtc {...props} />}
      {props.userData?.subtype === 'ad' && <ServerAd {...props} />}
      {props.userData?.subtype === 'amoc' && <ServerAmoc {...props} />}
      {props.userData?.subtype === 'dbs' && <ServerDbs {...props} />}
      {props.userData?.subtype === 'gigagenie' && <ServerGigagenie {...props} />}
      {props.userData?.subtype === 'icod' && <ServerIcod {...props} />}
      {props.userData?.subtype === 'icp' && <ServerIcp {...props} />}
      {props.userData?.subtype === 'image' && <ServerImage {...props} />}
      {props.userData?.subtype === 'mein' && <ServerMein {...props} />}
      {props.userData?.subtype === 'nvod' && <ServerNvod {...props} />}
      {props.userData?.subtype === 'obts' && <ServerObts {...props} />}
      {props.userData?.subtype === 'oms' && <ServerOms {...props} />}
    </group>
  )
})

export default Model

/**
 * 기타 서버
 * @param props
 * @returns
 */
const ServerEtc = (props: IModelProps) => {
  const gltf = useGLTF(modelSourceEtc) as any

  // 직접 사용하면 화면의 리프레시가 발생하면 애니메이션이 작동하지 않는다.
  const [scene] = useState<THREE.Scene>(gltf.scene.clone())
  scene.userData = props.userData || {}
  const { actions } = useAnimations(gltf.animations, scene)

  React.useEffect(() => {
    for (let action in actions) {
      actions[action]?.play()
    }
    return () => {
      for (let action in actions) {
        actions[action]?.fadeOut(100)
      }
    }
  }, [])

  return <primitive object={scene} />
}

/**
 * DVB 서버
 * @param props
 * @returns
 */
const ServerDvb = (props: IModelProps) => {
  const gltf = useGLTF(modelSourceDvb) as any

  // 직접 사용하면 화면의 리프레시가 발생하면 애니메이션이 작동하지 않는다.
  const [scene] = useState<THREE.Scene>(gltf.scene.clone())
  scene.userData = props.userData || {}
  const { actions } = useAnimations(gltf.animations, scene)

  React.useEffect(() => {
    for (let action in actions) {
      actions[action]?.play()
    }
    return () => {
      for (let action in actions) {
        actions[action]?.fadeOut(100)
      }
    }
  }, [])

  return <primitive object={scene} />
}

/**
 * CAS 서버
 * @param props
 * @returns
 */
const ServerCas = (props: IModelProps) => {
  const gltf = useGLTF(modelSourceCas) as any

  // 직접 사용하면 화면의 리프레시가 발생하면 애니메이션이 작동하지 않는다.
  const [scene] = useState<THREE.Scene>(gltf.scene.clone())
  scene.userData = props.userData || {}
  const { actions } = useAnimations(gltf.animations, scene)

  React.useEffect(() => {
    for (let action in actions) {
      actions[action]?.play()
    }
    return () => {
      for (let action in actions) {
        actions[action]?.fadeOut(100)
      }
    }
  }, [])

  return <primitive object={scene} />
}

const ServerAd = (props: IModelProps) => {
  const gltf = useGLTF(modelSourceAd) as any

  // 직접 사용하면 화면의 리프레시가 발생하면 애니메이션이 작동하지 않는다.
  const [scene] = useState<THREE.Scene>(gltf.scene.clone())
  scene.userData = props.userData || {}
  const { actions } = useAnimations(gltf.animations, scene)

  React.useEffect(() => {
    for (let action in actions) {
      actions[action]?.play()
    }
    return () => {
      for (let action in actions) {
        actions[action]?.fadeOut(100)
      }
    }
  }, [])
  return <primitive object={scene} />
}

const ServerAmoc = (props: IModelProps) => {
  const gltf = useGLTF(modelSourceAmoc) as any

  // 직접 사용하면 화면의 리프레시가 발생하면 애니메이션이 작동하지 않는다.
  const [scene] = useState<THREE.Scene>(gltf.scene.clone())
  scene.userData = props.userData || {}
  const { actions } = useAnimations(gltf.animations, scene)

  React.useEffect(() => {
    for (let action in actions) {
      actions[action]?.play()
    }
    return () => {
      for (let action in actions) {
        actions[action]?.fadeOut(100)
      }
    }
  }, [])
  return <primitive object={scene} />
}

const ServerDbs = (props: IModelProps) => {
  const gltf = useGLTF(modelSourceDbs) as any

  // 직접 사용하면 화면의 리프레시가 발생하면 애니메이션이 작동하지 않는다.
  const [scene] = useState<THREE.Scene>(gltf.scene.clone())
  scene.userData = props.userData || {}
  const { actions } = useAnimations(gltf.animations, scene)

  React.useEffect(() => {
    for (let action in actions) {
      actions[action]?.play()
    }
    return () => {
      for (let action in actions) {
        actions[action]?.fadeOut(100)
      }
    }
  }, [])
  return <primitive object={scene} />
}

const ServerGigagenie = (props: IModelProps) => {
  const gltf = useGLTF(modelSourceGigagenie) as any

  // 직접 사용하면 화면의 리프레시가 발생하면 애니메이션이 작동하지 않는다.
  const [scene] = useState<THREE.Scene>(gltf.scene.clone())
  scene.userData = props.userData || {}
  const { actions } = useAnimations(gltf.animations, scene)

  React.useEffect(() => {
    for (let action in actions) {
      actions[action]?.play()
    }
    return () => {
      for (let action in actions) {
        actions[action]?.fadeOut(100)
      }
    }
  }, [])
  return <primitive object={scene} />
}

const ServerIcod = (props: IModelProps) => {
  const gltf = useGLTF(modelSourceIcod) as any

  // 직접 사용하면 화면의 리프레시가 발생하면 애니메이션이 작동하지 않는다.
  const [scene] = useState<THREE.Scene>(gltf.scene.clone())
  scene.userData = props.userData || {}
  const { actions } = useAnimations(gltf.animations, scene)

  React.useEffect(() => {
    for (let action in actions) {
      actions[action]?.play()
    }
    return () => {
      for (let action in actions) {
        actions[action]?.fadeOut(100)
      }
    }
  }, [])
  return <primitive object={scene} />
}

const ServerIcp = (props: IModelProps) => {
  const gltf = useGLTF(modelSourceIcp) as any

  // 직접 사용하면 화면의 리프레시가 발생하면 애니메이션이 작동하지 않는다.
  const [scene] = useState<THREE.Scene>(gltf.scene.clone())
  scene.userData = props.userData || {}
  const { actions } = useAnimations(gltf.animations, scene)

  React.useEffect(() => {
    for (let action in actions) {
      actions[action]?.play()
    }
    return () => {
      for (let action in actions) {
        actions[action]?.fadeOut(100)
      }
    }
  }, [])
  return <primitive object={scene} />
}

const ServerImage = (props: IModelProps) => {
  const gltf = useGLTF(modelSourceImage) as any

  // 직접 사용하면 화면의 리프레시가 발생하면 애니메이션이 작동하지 않는다.
  const [scene] = useState<THREE.Scene>(gltf.scene.clone())
  scene.userData = props.userData || {}
  const { actions } = useAnimations(gltf.animations, scene)

  React.useEffect(() => {
    for (let action in actions) {
      actions[action]?.play()
    }
    return () => {
      for (let action in actions) {
        actions[action]?.fadeOut(100)
      }
    }
  }, [])
  return <primitive object={scene} />
}

const ServerMein = (props: IModelProps) => {
  const gltf = useGLTF(modelSourceMein) as any

  // 직접 사용하면 화면의 리프레시가 발생하면 애니메이션이 작동하지 않는다.
  const [scene] = useState<THREE.Scene>(gltf.scene.clone())
  scene.userData = props.userData || {}

  const { actions } = useAnimations(gltf.animations, scene)

  React.useEffect(() => {
    for (let action in actions) {
      actions[action]?.play()
    }
    return () => {
      for (let action in actions) {
        actions[action]?.fadeOut(100)
      }
    }
  }, [])
  
  return <primitive object={scene} />
}

const ServerNvod = (props: IModelProps) => {
  const gltf = useGLTF(modelSourceNvod) as any

  // 직접 사용하면 화면의 리프레시가 발생하면 애니메이션이 작동하지 않는다.
  const [scene] = useState<THREE.Scene>(gltf.scene.clone())
  scene.userData = props.userData || {}
  const { actions } = useAnimations(gltf.animations, scene)

  React.useEffect(() => {
    for (let action in actions) {
      actions[action]?.play()
    }
    return () => {
      for (let action in actions) {
        actions[action]?.fadeOut(100)
      }
    }
  }, [])
  return <primitive object={scene} />
}

const ServerObts = (props: IModelProps) => {
  const gltf = useGLTF(modelSourceObts) as any

  // 직접 사용하면 화면의 리프레시가 발생하면 애니메이션이 작동하지 않는다.
  const [scene] = useState<THREE.Scene>(gltf.scene.clone())
  scene.userData = props.userData || {}
  const { actions } = useAnimations(gltf.animations, scene)

  React.useEffect(() => {
    for (let action in actions) {
      actions[action]?.play()
    }
    return () => {
      for (let action in actions) {
        actions[action]?.fadeOut(100)
      }
    }
  }, [])
  return <primitive object={scene} />
}

const ServerOms = (props: IModelProps) => {
  const gltf = useGLTF(modelSourceOms) as any

  // 직접 사용하면 화면의 리프레시가 발생하면 애니메이션이 작동하지 않는다.
  const [scene] = useState<THREE.Scene>(gltf.scene.clone())
  scene.userData = props.userData || {}
  const { actions } = useAnimations(gltf.animations, scene)

  React.useEffect(() => {
    for (let action in actions) {
      actions[action]?.play()
    }
    return () => {
      for (let action in actions) {
        actions[action]?.fadeOut(100)
      }
    }
  }, [])
  return <primitive object={scene} />
}
