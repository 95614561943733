import React from 'react'
import { Text } from '../../../shapes'
import { ChartProps } from '../Chart'
import { Svg } from '../Svg'
import ClockChart from './ClockChart'

export const IPAcoChart = ({
  value = ['No Name', 'OF/300', 297],
  color = 0xffffff,
  update
}: ChartProps) => {
  const [_value, setValue] = React.useState(value)

  React.useLayoutEffect(() => {
    setValue(value)
  }, [value])

  React.useLayoutEffect(() => {
    if (update) {
      let isAlive = true
      const _update = () => {
        ;(update() as Promise<any>).then((value) => {
          if (isAlive) {
            setValue(value)
            requestAnimationFrame(_update)
          }
        })
      }
      requestAnimationFrame(_update)
      return () => {
        isAlive = false
      }
    }
    return () => {}
  }, [update])

  return (
    <ClockChart
      value={_value[2]}
      color={color as THREE.ColorRepresentation}
      name='IP-ACO'
    >
      <Text
        position={[-0.3, 0, -1]}
        color={color as THREE.ColorRepresentation}
        fontSize={0.25}
        fontWeight='Bold'
        anchorX={'left'}
        anchorY={'middle'}
      >
        {_value[0]}
      </Text>
      <Text
        position={[0.1, 0, -1]}
        color={color as THREE.ColorRepresentation}
        fontSize={0.25}
        fontWeight='Medium'
        anchorX={'left'}
        anchorY={'middle'}
      >
        {_value[1]}
      </Text>

      <Svg
        color={color as THREE.ColorRepresentation}
        depth={10}
        opacity={1}
        position={[0.32775, 0, 0.32775]}
        rotation={[0, Math.PI / 2, 0]}
        scale={[1.5, 1.1, 1.5]}
        data={`<svg viewBox="0 0 45 45">
          <path d="M42.64,12.44l1.57-7.68s.57-2.15-.63-3.35-3.35-.62-3.35-.62l-7.68,1.57c-3.08-1.54-6.51-2.36-10.06-2.36-6.01,0-11.66,2.34-15.91,6.59C2.34,10.84,0,16.49,0,22.5s2.34,11.66,6.59,15.91c4.25,4.25,9.9,6.59,15.91,6.59s11.66-2.34,15.91-6.59c4.25-4.25,6.59-9.9,6.59-15.91,0-3.55-.82-6.97-2.36-10.06h0ZM5.97,26.22c-.25-.25-.41-.55-.48-.87h0c-.88-5.32,.72-10.97,4.81-15.06,4.09-4.09,9.74-5.69,15.05-4.81h0c.32,.08,.62,.24,.87,.49,.7,.7,.7,1.84,0,2.54L8.51,26.22c-.7,.7-1.84,.7-2.54,0h0Zm28.74,8.48c-4.09,4.09-9.74,5.69-15.05,4.81h0c-.32-.08-.62-.24-.87-.49-.7-.7-.7-1.84,0-2.54l17.71-17.71c.7-.7,1.84-.7,2.54,0,.25,.25,.41,.55,.48,.87h0c.88,5.32-.72,10.97-4.81,15.06h0Zm4.06-24.38c-.03,.17-.12,.33-.25,.46h0L14.57,34.74c-1.04,1.04-2.65,1.17-3.84,.38-.15-.14-.29-.28-.43-.42-.14-.14-.28-.29-.42-.43-.79-1.18-.66-2.79,.38-3.83L34.22,6.48c.13-.13,.29-.21,.46-.25h0l5.15-1.06-1.06,5.15Z" />
        </svg>`}
      />
    </ClockChart>
  )
}

export default IPAcoChart
