import React from 'react'
import { Text } from '../../../shapes'
import { ChartProps } from '../Chart'
import { Svg } from '../Svg'
import ClockChart from './ClockChart'

export const MuxChart = ({
  value = ['No Name', 297],
  color = 0xffffff,
  update
}: ChartProps) => {
  const [_value, setValue] = React.useState(value)

  React.useLayoutEffect(() => {
    setValue(value)
  }, [value])

  React.useLayoutEffect(() => {
    if (update) {
      let isAlive = true
      const _update = () => {
        ;(update() as Promise<any>).then((value) => {
          if (isAlive) {
            setValue(value)
            requestAnimationFrame(_update)
          }
        })
      }
      requestAnimationFrame(_update)
      return () => {
        isAlive = false
      }
    }
    return () => {}
  }, [update])

  return (
    <ClockChart
      value={_value[2]}
      color={color as THREE.ColorRepresentation}
      name='IP-NUX'
    >
      <Text
        position={[-0.3, 0, -1]}
        color={color as THREE.ColorRepresentation}
        fontSize={0.25}
        fontWeight='Bold'
        anchorX={'left'}
        anchorY={'middle'}
      >
        {_value[0]}
      </Text>
      <Text
        position={[0.1, 0, -1]}
        color={color as THREE.ColorRepresentation}
        fontSize={0.25}
        fontWeight='Medium'
        anchorX={'left'}
        anchorY={'middle'}
      >
        {_value[1]}
      </Text>

      <Svg
        color={color as THREE.ColorRepresentation}
        depth={10}
        opacity={1}
        position={[0.4, 0, 0.45]}
        rotation={[0, -(Math.PI * 3) / 2, 0]}
        scale={[1.2, 1.1, 1.2]}
        data={`<svg><path d="M66.42,22.39c-.52-.97-1.54-1.57-2.64-1.57h-15.28l6.8-11.77h-4.48V0h-6V9.05h-4.48l6.79,11.77H19.64l6.8-11.77h-4.48V0h-6V9.05h-4.48l6.79,11.77H3c-1.1,0-2.11,.6-2.64,1.57s-.48,2.15,.12,3.07l15.27,23.4c.55,.85,1.5,1.36,2.51,1.36h12.12v16.05h-4.48l7.48,12.95,7.48-12.95h-4.48v-16.05h12.12c1.01,0,1.96-.51,2.51-1.36l15.27-23.4c.6-.92,.65-2.1,.12-3.07Zm-19.53,21.83H19.89L8.54,26.82H58.25l-11.35,17.4Z"/></svg>`}
      />
    </ClockChart>
  )
}

export default MuxChart
