import React from 'react'
import { Oneview3DLetters } from '../oneview'
import PlaneWithName from './PlaneWithName'
import PlaneWithoutName from './PlaneWithoutName'
import { Rect } from './Rect'

export type ShapeProps = {
  id: string
  name: string
  shape: string
  position: THREE.Vector3Tuple
  rotation: THREE.Vector3Tuple
  scale: THREE.Vector3Tuple
  color: THREE.ColorRepresentation
  userData: { [key: string]: any }
}

export default ({
  id,
  shape = 'plane-no-name',
  name = 'No Name',
  position = [0, 0, 0],
  rotation = [0, 0, 0],
  scale = [1, 1, 1],
  color = '#ffffff',
  userData = {}
}: ShapeProps) => {
  const modelRef = React.useRef<THREE.Object3D>()

  return (
    <group
      uuid={id}
      scale={scale}
      position={position}
      rotation={rotation}
      name='NETWORK-DIAGRAM-SHAPE'
      userData={userData}
    >
      {shape === 'text3d' && (
        <Oneview3DLetters name={name} ref={modelRef} userData={userData} />
      )}

      {shape === 'subplane' && (
        <Rect ref={modelRef} scale={[2, 1, 1]} color={'#ff00ff'}>
          {name}
        </Rect>
      )}

      {shape === 'plane-no-name' && (
        <PlaneWithoutName
          ref={modelRef}
          scale={scale}
          color={color}
          userData={userData}
        />
      )}
      {shape === 'plane-with-name' && (
        <PlaneWithName
          name={name}
          ref={modelRef}
          scale={scale}
          color={color}
          userData={userData}
        />
      )}
    </group>
  )
}
