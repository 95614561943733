// prettier-ignore
export const NodeTypes = {
  Unknown: {            code: 1001000, name: '알수없음',                 pcode: 1001, },
  Encoder: {            code: 1001001, name: 'Encoder',                  pcode: 1001, },
  IPAcoMux: {           code: 1001002, name: 'IP-ACO MUX',               pcode: 1001, },
  MUX: {                code: 1001003, name: 'MUX',                      pcode: 1001, },
  L3: {                 code: 1001004, name: 'L3',                       pcode: 1001, },
  Transcoder: {         code: 1001005, name: 'Transcoder',               pcode: 1001, },
  Transmission: {       code: 1001006, name: 'Transcoder',               pcode: 1001, },
  MSPP: {               code: 1001007, name: '전송 장비',                pcode: 1001, },
  Server: {             code: 1001008, name: '비디오스티칭',             pcode: 1001, },
  Satellite: {          code: 1001009, name: '위성',                     pcode: 1001, },
  Aws: {                code: 1001010, name: 'Aws',                      pcode: 1001, },
  Skylife: {            code: 1001011, name: 'Skylife',                  pcode: 1001, },
  Typography: {         code: 1002012, name: '2D 글자',                  pcode: 1001, },
  Plane: {              code: 1002013, name: '원뷰 평면',                pcode: 1002, },
  Rect: {               code: 1002014, name: '평면',                     pcode: 1002, },
  Cloud: {              code: 1001015, name: 'PremiumNetwork',           pcode: 1001, },
  // TEXT_3D_BAEKSEOK: {   code: 1002014, name: '원뷰 3D글자 백석',         pcode: 1002, },
  // TEXT_3D_YEOUIDO: {    code: 1002015, name: '원뷰 3D글자 여의도',       pcode: 1002, },
  // TEXT_3D_LGUPLUS: {    code: 1002016, name: '원뷰 3D글자 LGU+',         pcode: 1002, },
  // TEXT_3D_GROUNDWAVE: { code: 1002017, name: '원뷰 3D글자 지상파',       pcode: 1002, },
  // TEXT_3D_PP: {         code: 1002018, name: '원뷰 3D글자 PP',           pcode: 1002, },
  // TEXT_3D_CUG: {        code: 1002019, name: '원뷰 3D글자 CUG',          pcode: 1002, },
  // TEXT_3D_SATELLITE: {  code: 1002020, name: '원뷰 3D글자 위성',         pcode: 1002, },
  // TEXT_3D_AWS: {        code: 1002021, name: '원뷰 3D글자 AWS',          pcode: 1002, },
  // CHART_3D_PLANE: {     code: 1002022, name: '원뷰 3D차트 평면 차트',    pcode: 1002, },
  // CHART_3D_SYLINDER: {  code: 1002023, name: '원뷰 3D차트 실린더 차트',  pcode: 1002, },
  // CHART_3D_BLOCK: {     code: 1002024, name: '원뷰 3D차트 블록 차트',    pcode: 1002, },
  // CHART_3D_SWITCH: {    code: 1002025, name: '원뷰 3D차트 스위치 차트',  pcode: 1002, },
  // CHART_3D_IPACO: {     code: 1002026, name: '원뷰 3D차트 IP-ACO 차트',  pcode: 1002, },
  // CHART_3D_MUX: {       code: 1002027, name: '원뷰 3D차트 MUX 차트',     pcode: 1002, },
  // CHART_3D_ENCODER: {   code: 1002028, name: '원뷰 3D차트 Encoder 차트', pcode: 1002, },
  Seezn: {              code: 1001029, name: 'Seezn',                    pcode: 1001, },
}

export type TNodeTypes = keyof typeof NodeTypes

export type TNodeServerSubTypes =
  | 'etc'
  | 'cas'               
  | 'dvb'
  | 'ad'
  | 'amoc'
  | 'dbs'
  | 'gigagenie'
  | 'icod'
  | 'icp'
  | 'image'
  | 'mein'
  | 'nvod'
  | 'obts'
  | 'oms'
