import React from 'react'
import { Text } from '../../../shapes'
import { ChartProps } from '../Chart'
import { Svg } from '../Svg'
import { Cylinder } from './Cylinder'

export const ChartBottomRight1 = ({
  value = ['No Name', 'No Name', 99, 5, 7, 3, 9],
  color = 0xffffff,
  update
}: ChartProps) => {
  const [_value, setValue] = React.useState(value)

  React.useLayoutEffect(() => {
    setValue(value)
  }, [value])

  React.useLayoutEffect(() => {
    if (update) {
      let isAlive = true
      const _update = () => {
        ;(update() as Promise<any>).then((value) => {
          if (isAlive) {
            setValue(value)
            requestAnimationFrame(_update)
          }
        })
      }
      requestAnimationFrame(_update)
      return () => {
        isAlive = false
      }
    }
    return () => {}
  }, [update])

  return (
    <group name='ChartBottomRight1'>
      <Text
        position={[-0.25, 0, -0.65]}
        color='#ffffff'
        fontSize={0.1}
        fontWeight='Bold'
      >
        {_value[0]}
      </Text>
      <Text
        position={[0.685, 0, -0.65]}
        color='#ffffff'
        fontSize={0.1}
        fontWeight='Light'
      >
        {_value[1]} Streams
      </Text>
      <Text
        position={[0.85, 0, -0.65]}
        color='#ffffff'
        fontSize={0.1}
        fontWeight='Light'
      >
        Bandwidth: {_value[2]} Gbps
      </Text>
      <Svg
        depth={0.01}
        color='#ffffff'
        data={`<svg><polygon points="9.53 65.77 9.53 66.38 1.12 66.43 .93 1.67 8.67 1.63 7.83 2.28 8.09 2.5 9.21 1.62 9.31 1.62 9.31 1.55 9.71 1.24 9.31 .93 9.31 .86 9.21 .86 8.08 0 7.83 .23 8.67 .86 0 .91 .2 67.2 9.53 67.15 9.54 67.76 9.9 67.76 9.9 65.77 9.53 65.77" style="fill:#ccc;"/><polygon points="131.51 67.47 131.31 1.18 121.97 1.23 121.97 .62 121.6 .62 121.61 2.61 121.98 2.61 121.98 2 130.39 1.95 130.58 66.71 122.84 66.75 123.67 66.11 123.42 65.88 122.29 66.76 122.2 66.76 122.2 66.83 121.8 67.14 122.2 67.45 122.2 67.52 122.3 67.52 123.43 68.38 123.68 68.16 122.84 67.52 131.51 67.47" style="fill:#ccc;"/></svg>`}
      />
      <Cylinder
        value={_value[3]}
        scale={0.1}
        position={[0.35, 0, -0.2]}
        color={color as THREE.ColorRepresentation}
      />
      <Cylinder
        value={_value[4]}
        scale={0.1}
        position={[0.35, 0, -0.5]}
        color={color as THREE.ColorRepresentation}
      />
      <Cylinder
        value={_value[5]}
        scale={0.1}
        position={[0.35, 0, -0.8]}
        color={color as THREE.ColorRepresentation}
      />
      <Cylinder
        value={_value[6]}
        scale={0.1}
        position={[0.35, 0, -1.1]}
        color={color as THREE.ColorRepresentation}
      />
    </group>
  )
}
