import React, { forwardRef, useMemo, useState } from 'react'
import { ExtrudeGeometry, Vector3 } from 'three'
import { transformSVGPath } from '../../utils/d3threeD'
import { PlaneProps } from './Plane'

const PlaneWithoutName = forwardRef(
  ({ scale = [1, 0, 1], color = '#3377ff' }: PlaneProps, ref: any) => {
    const [scale0, setScale] = useState<Vector3>(new Vector3(...scale))

    const geometry = useMemo(() => {
      const _scale = scale0.clone().multiply(new Vector3(...scale))

      const planePath = `M60.1 30.95 h${
        179.12 * _scale.x
      }c5.52 0 10 4.48 10 10 v${
        179.12 * _scale.y
      } c 0 16.56 -13.44 30 -30 30 H40.1c-5.52 0-10-4.48-10-10V60.95c0-16.56 13.44-30 30-30z`

      const shape = transformSVGPath(planePath)
      const geo = new ExtrudeGeometry(shape, {
        depth: 15,
        bevelEnabled: true,
        bevelSegments: 12,
        steps: 6,
        bevelSize: 6,
        bevelThickness: 6
      })

      geo.scale(0.01, 0.01, 0.0025)
      geo.center()

      ref.current?.scale.set(1, 1, 1)
      setScale(_scale)
      return geo
    }, [scale])

    return (
      <mesh
        ref={ref}
        name='NETWORK-DIAGRAM-NODE-MODEL'
        position={[0, 0, -0.015]}
        geometry={geometry}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
      >
        <meshBasicMaterial
          attach='material'
          color={color}
          transparent={true}
          opacity={0.125}
        ></meshBasicMaterial>
      </mesh>
    )
  }
)
export default PlaneWithoutName
