import { Menu, MenuButton, MenuItem } from '@szhsin/react-menu'
import '@szhsin/react-menu/dist/index.css'

import React from 'react'
import { useNetworkDiagram } from '../../../contexts'

export default function ViewMenu() {
  const { context } = useNetworkDiagram()

  return (
    <Menu
      menuButton={
        <MenuButton
          style={{
            background: 'transparent',
            color: context.theme === 'dark' ? '#fff' : '#000',
            border: 0,
            marginLeft: '20px'
          }}
        >
          보기
        </MenuButton>
      }
      theming={'dark'}
    >
      <MenuItem>미리보기</MenuItem>
      {/* <MenuDivider />
      <MenuItem>속성</MenuItem> */}
    </Menu>
  )
}
