import React from 'react'

import { useGLTF } from '@react-three/drei'
import { GroupProps, useThree } from '@react-three/fiber'
import { Color, MeshStandardMaterial } from 'three'

const modelSource = '/assets/models/chart-block.gltf'

type CubeProps = GroupProps & {
  value: number
  color: THREE.ColorRepresentation
}

export const Cube = ({ value, color, ...props }: CubeProps) => {
  useGLTF.preload(modelSource)
  const {
    nodes: {
      Cube: { geometry }
    }
  } = useGLTF(modelSource) as any

  const [material0, material1] = React.useMemo(() => {
    const _color = new Color(color)
    _color.convertSRGBToLinear()
    const mat0 = new MeshStandardMaterial({
      color: _color,
      transparent: true,
      opacity: 0.75
    })
    const mat1 = mat0.clone()
    // mat0.color.addScalar(-0.5)
    mat1.opacity = 0.95
    return [mat0, mat1]
  }, [color])

  const cubes = React.useMemo(() => {
    geometry.computeBoundingBox()
    const box = geometry.boundingBox
    const w = box.max.x - box.min.x
    const h = box.max.y - box.min.y
    const d = box.max.z - box.min.z

    const cubes: any = []
    const counts = Math.ceil(value / 10)

    for (let i = 0; i < counts; i++) {
      const z = -(i % 5)
      const y = Math.floor(i / 25)
      const x = -Math.floor((i % 25) / 5)
      const cube = (
        <mesh
          key={i}
          geometry={geometry}
          material={
            i + 1 < counts || value === counts * 10 ? material1 : material0
          }
          position={[x * w, y * h, z * d]}
        ></mesh>
      )
      cubes.push(cube)
    }
    return cubes
  }, [value])

  const scene = useThree((state) => state.scene)

  React.useEffect(() => {
    return () => {
      for (const element of cubes) {
        const mesh = element
        if (mesh) {
          mesh.material?.dispose()
          mesh.geometry?.dispose()
          scene.remove(mesh)
        }
      }
      geometry.dispose()
      material0.dispose()
      material1.dispose()
      scene.remove(cubes)
    }
  }, [value])

  return (
    <group scale={[0.08, 0.08, 0.08]} {...props} name='Cubes'>
      {cubes}
    </group>
  )
}

export default Cube
