import { useAnimations, useGLTF } from '@react-three/drei'
import React, { useState } from 'react'
import { IModelProps } from '../node/Model'

const modelSourcePower = '/assets/models/error-power.gltf'
useGLTF.preload(modelSourcePower)

const modelSourcePowers = '/assets/models/error-powers.gltf'
useGLTF.preload(modelSourcePowers)

const modelSourceTemperature = '/assets/models/error-temperature.gltf'
useGLTF.preload(modelSourceTemperature)

const modelSourceService = '/assets/models/error-service.gltf'
useGLTF.preload(modelSourceService)

const modelSourceBreakdown = '/assets/models/error-breakdown.gltf'
useGLTF.preload(modelSourceBreakdown)

const Model = (props: IModelProps) => {
  return (
    <group position={props.position}>
      {props.userData?.error === 'power' && <ErrorPower {...props} />}
      {props.userData?.error === 'powers' && <ErrorPowers {...props} />}
      {props.userData?.error === 'temperature' && (
        <ErrorTemperature {...props} />
      )}
      {props.userData?.error === 'service' && <ErrorService {...props} />}
      {props.userData?.error === 'breakdown' && <ErrorBreakdown {...props} />}
    </group>
  )
}

export default Model

/**
 * 전력 알람 표시
 * @param props
 * @returns
 */
const ErrorPower = (props: IModelProps) => {
  const gltf = useGLTF(modelSourcePower) as any

  const [scene] = useState<THREE.Scene>(gltf.scene.clone())
  scene.userData = props.userData || {}

  const { actions } = useAnimations(gltf.animations, scene)

  React.useEffect(() => {
    for (let action in actions) {
      actions[action]?.play()
    }

    return () => {
      for (let action in actions) {
        actions[action]?.fadeOut(100)
      }
    }
  }, [])

  return <primitive object={scene} />
}

/**
 * 전원 알람 표시
 * @param props
 * @returns
 */
const ErrorPowers = (props: IModelProps) => {
  const gltf = useGLTF(modelSourcePowers) as any

  const [scene] = useState<THREE.Scene>(gltf.scene.clone())
  scene.userData = props.userData || {}

  const { actions } = useAnimations(gltf.animations, scene)

  React.useEffect(() => {
    for (let action in actions) {
      actions[action]?.play()
    }
    return () => {
      for (let action in actions) {
        actions[action]?.fadeOut(100)
      }
    }
  }, [])

  return <primitive object={scene} />
}

/**
 * 온도 알람 표시
 * @param props
 * @returns
 */
const ErrorTemperature = (props: IModelProps) => {
  const gltf = useGLTF(modelSourceTemperature) as any

  const [scene] = useState<THREE.Scene>(gltf.scene.clone())
  scene.userData = props.userData || {}

  const { actions } = useAnimations(gltf.animations, scene)

  React.useEffect(() => {
    for (let action in actions) {
      actions[action]?.play()
    }
    return () => {
      for (let action in actions) {
        actions[action]?.fadeOut(100)
      }
    }
  }, [])

  return <primitive object={scene} />
}

/**
 * 서비스 알람 표시
 * @param props
 * @returns
 */
const ErrorService = (props: IModelProps) => {
  const gltf = useGLTF(modelSourceService) as any

  const [scene] = useState<THREE.Scene>(gltf.scene.clone())
  scene.userData = props.userData || {}

  const { actions } = useAnimations(gltf.animations, scene)

  React.useEffect(() => {
    for (let action in actions) {
      actions[action]?.play()
    }
    return () => {
      for (let action in actions) {
        actions[action]?.fadeOut(100)
      }
    }
  }, [])

  return <primitive object={scene} />
}

/**
 * 고장 알람 표시
 *
 * @param props
 * @returns
 */
const ErrorBreakdown = (props: IModelProps) => {
  const gltf = useGLTF(modelSourceBreakdown) as any

  const [scene] = useState<THREE.Scene>(gltf.scene.clone())
  scene.userData = props.userData || {}

  const { actions } = useAnimations(gltf.animations, scene)

  React.useEffect(() => {
    for (let action in actions) {
      actions[action]?.play()
    }
    return () => {
      for (let action in actions) {
        actions[action]?.fadeOut(100)
      }
    }
  }, [])

  return <primitive object={scene} />
}
