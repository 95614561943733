import { useFrame, useThree } from '@react-three/fiber'
import React, { forwardRef, useLayoutEffect, useState } from 'react'
import { CanvasTexture, CurvePath, Vector3 } from 'three'

type MarkerProps = {
  path: CurvePath<Vector3>
  delay: number
  color?: string
}

export const Marker = forwardRef(
  ({ path, delay = 0, color = '#fff' }: MarkerProps, ref: any) => {
    const [delta, setDelta] = useState<number>(0.1)
    const [fraction, setFraction] = useState<number>(0)
    const [position, setPosition] = useState<Vector3>(new Vector3())

    const [begin, setBegin] = useState<boolean>(false)
    const [visible, setVisible] = useState<boolean>(false)

    useLayoutEffect(() => {
      const length = path.getLength()
      if (length > 1) {
        setDelta(0.1 / length)
      } else if (length > 0) {
        setDelta(0.1 * (length * length))
      } else {
        setDelta(0)
      }

      const timeoutid = setTimeout(() => {
        setBegin(true)
      }, delay * 300)

      return () => {
        clearTimeout(timeoutid)
        setBegin(false)
      }
    }, [path])

    const updateMarker = () => {
      if (!begin) {
        return
      }
      setFraction((fraction = 0) => {
        const f = fraction + (delta || 0.1)
        return f > 1 ? 0 : f
      })

      setPosition(path.getPoint(fraction))
      setVisible(true)
    }

    useFrame(updateMarker)

    const gl = useThree((state) => state.gl)

    const texture = React.useMemo(() => {
      const canvas = document.createElement('canvas')
      canvas.width = 64
      canvas.height = 64

      const context = canvas.getContext('2d')!
      context.beginPath()
      context.arc(32, 32, 16, 0, 2 * Math.PI)
      context.closePath()
      context.fillStyle = color
      context.fill()
      return new CanvasTexture(canvas)
    }, [])

    return (
      <group ref={ref} position={position} visible={visible}>
        <sprite scale={0.2}>
          <spriteMaterial
            map={texture}
            map-encoding={gl.outputEncoding}
            map-anisotropy={gl.capabilities.getMaxAnisotropy() || 1}
            alphaTest={0.1}
            toneMapped={false}
            transparent={true}
            opacity={0.6}
          />
        </sprite>
      </group>
    )
  }
)
