import { useGLTF } from '@react-three/drei'
import React from 'react'

const modelSource = '/assets/models/letters/AWS.gltf'
useGLTF.preload(modelSource)

export const AWS = () => {
  const gltf = useGLTF(modelSource) as any

  return <primitive object={gltf.scene.clone()} />
}
