import React, { forwardRef } from 'react'
import {
  animated,
  easings,
  useChain,
  useSpring,
  useSpringRef
} from '@react-spring/three'

import { IModelProps } from '../../../node/Model'
import { Arc } from '../Arc'
import { Text } from '@react-three/drei'

const Model = forwardRef((props: IModelProps, ref: any) => {
  const ref1 = useSpringRef()
  const { rotate1 } = useSpring({
    ref: ref1,
    config: { duration: 3000, easing: easings.easeInOutQuart },
    loop: true,
    from: { rotate1: 0 },
    to: { rotate1: -Math.PI * 2 }
  })
  const ref2 = useSpringRef()
  const { rotate2 } = useSpring({
    ref: ref2,
    config: { duration: 1000, easing: easings.easeInOutQuart },
    loop: { reverse: true },
    from: { rotate2: -Math.PI },
    to: { rotate2: -Math.PI * 2 }
  })
  useChain([ref1, ref2], [0, 0.66667])

  return (
    <group
      name='NETWORK-DIAGRAM-CHART'
      ref={ref}
      position={props.position}
      rotation={[-Math.PI / 2, 0, 0]}
      scale={props.scale}
    >
      <animated.group rotation-z={rotate1}>
        <Arc
          aRadius={1}
          aRadius2={0.8}
          aStartAngle={180}
          aEndAngle={360}
          color={'#1f91be'}
          aClockwise={true}
          animate={false}
        />
        <animated.group rotation-z={rotate2}>
          <Arc
            aRadius={1}
            aRadius2={0.8}
            aStartAngle={0}
            aEndAngle={175}
            color={'#1f91be'}
            aClockwise={true}
            animate={false}
          />
        </animated.group>
      </animated.group>

      {[0, 36, 72, 108, 144, 180, 216, 252, 288, 324, 360].map((angle, i) => (
        <Arc
          key={i}
          aRadius={0.6}
          aRadius2={0.55}
          aStartAngle={angle - 36}
          aEndAngle={angle - 15}
          color={'#1f91be'}
          aClockwise={true}
          animate={false}
        />
      ))}

      <Text
        color={0xffffff}
        fontSize={0.2}
        letterSpacing={0}
        textAlign={'center'}
        anchorX={'center'}
        anchorY={'middle'}
        font={`${
          process.env.REACT_APP_BASE_URL || ''
        }assets/fonts/KTfontBold.ttf`}
        material-depthTest={false}
        material-depthWrite={false}
        outlineWidth={'15%'}
        outlineColor='#0f4e9f'
        outlineOpacity={0}
      >
        IP ACO
      </Text>
    </group>
  )
})

export default Model
