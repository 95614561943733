import React from 'react'
import { Text } from '../../../shapes'
import { ChartProps } from '../Chart'
import { Svg } from '../Svg'
import { ClockChart } from './ClockChart'

export const EncoderChart = ({
  value = ['No Name', 'OF/300', 90],
  color = 0xffffff,
  update
}: ChartProps) => {
  const [_value, setValue] = React.useState(value)

  React.useLayoutEffect(() => {
    setValue(value)
  }, [value])

  React.useLayoutEffect(() => {
    if (update) {
      let isAlive = true
      const _update = () => {
        ;(update() as Promise<any>).then((value) => {
          if (isAlive) {
            setValue(value)
            requestAnimationFrame(_update)
          }
        })
      }
      requestAnimationFrame(_update)
      return () => {
        isAlive = false
      }
    }
    return () => {}
  }, [update])

  return (
    <ClockChart
      value={_value[2]}
      color={color as THREE.ColorRepresentation}
      name='Encoder'
    >
      <Text
        position={[-0.3, 0, -1]}
        color={color as THREE.ColorRepresentation}
        fontSize={0.25}
        fontWeight='Bold'
        anchorX={'left'}
        anchorY={'middle'}
      >
        {_value[0]}
      </Text>
      <Text
        position={[0.1, 0, -1]}
        color={color as THREE.ColorRepresentation}
        fontSize={0.25}
        fontWeight='Medium'
        anchorX={'left'}
        anchorY={'middle'}
      >
        {_value[1]}
      </Text>

      <Svg
        color={color as THREE.ColorRepresentation}
        depth={10}
        opacity={1}
        position={[0.28, 0, 0.28]}
        rotation={[0, Math.PI / 2, 0]}
        scale={[1.3, 1.1, 1.3]}
        data={`<svg viewBox="0 0 45 45">
          <rect x="9" width="4.5" height="4.5" />
          <rect x="13.5" y="4.5" width="4.5" height="4.5" />
          <rect x="22.5" y="4.5" width="4.5" height="4.5" />
          <rect x="31.5" y="4.5" width="4.5" height="4.5" />
          <rect x="18" y="9" width="4.5" height="4.5" />
          <rect x="22.5" y="13.5" width="4.5" height="4.5" />
          <rect x="31.5" y="13.5" width="4.5" height="4.5" />
          <rect x="40.5" y="13.5" width="4.5" height="4.5" />
          <rect x="27" y="18" width="4.5" height="4.5" />
          <rect x="31.5" y="22.5" width="4.5" height="4.5" />
          <rect x="40.5" y="22.5" width="4.5" height="4.5" />
          <rect x="36" y="27" width="4.5" height="4.5" />
          <polygon
              points="36 31.5 31.5 31.5 31.5 27 27 27 27 22.5 22.5 22.5 22.5 18 18 18 18 13.5 13.5 13.5 13.5 9 9 9 9 4.5 4.5 4.5 4.5 0 0 0 0 4.5 0 9 0 13.5 0 18 0 22.5 0 27 0 31.5 0 36 0 40.5 0 45 4.5 45 9 45 13.5 45 18 45 22.5 45 27 45 31.5 45 36 45 40.5 45 45 45 45 40.5 40.5 40.5 40.5 36 36 36 36 31.5" />
          <rect x="40.5" y="31.5" width="4.5" height="4.5" />
        </svg>`}
      />
    </ClockChart>
  )
}

export default EncoderChart
