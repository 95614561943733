import {
  MapControls,
  Select,
  Stats,
  TransformControls,
  useContextBridge
} from '@react-three/drei'
import { Canvas, RootState } from '@react-three/fiber'
import React, { forwardRef, useEffect, useRef, useState } from 'react'
import { Box3, Euler, Group, Object3D, Scene, Vector2, Vector3 } from 'three'
import {
  MapControls as MapControlsImpl,
  TransformControls as TransformControlsImpl
} from 'three-stdlib'

import Hub from '@mui/icons-material/Hub'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'

// tool icons
import DragHandleRoundedIcon from '@mui/icons-material/DragHandleRounded'
import OpenWithRoundedIcon from '@mui/icons-material/OpenWithRounded'
import PhotoSizeSelectSmallRoundedIcon from '@mui/icons-material/PhotoSizeSelectSmallRounded'
import ThreeSixtyRoundedIcon from '@mui/icons-material/ThreeSixtyRounded'

import { getNode } from '../../utils/nodeUtils'
import { Link, LinkProps } from '../link/Link'
import { Node, NodeProps } from '../node/Node'
import { ViewportData } from '../Viewport'

import styled from '@emotion/styled'
import {
  NetworkDiagramContext,
  TNetworkDiagramContext,
  useNetworkDiagram
} from '../../contexts'

import * as DraggableClass from 'react-draggable'
import { getPointsFromArray } from '../../utils/getPointsFromArray'
import Chart, { ChartProps } from '../oneview/charts/Chart'
import Shape, { ShapeProps } from '../shapes/Shape'
import { AddLinkControls } from './AddLinkControls'
import EditMenu from './appbar/Edit'
import FileMenu from './appbar/File'
import ViewMenu from './appbar/View'

// appbar menu dark theme
import('@szhsin/react-menu/dist/theme-dark.css')

const Draggable: any = DraggableClass.default

// mouse position
export const pointer = new Vector2(Infinity, Infinity)

const DesignerAppToolbar = styled.div<{ theme: string }>(
  {
    width: '100%',
    height: '28px',
    fontSize: '12px',
    fontWeight: 400,
    fontFamily: "'NotoSansKR', 'Roboto', 'sans-serif'",
    color: '#fff',
    boxSizeing: 'border-box',
    borderBottom: '1px solid #555',
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  ({ theme }) =>
    theme === 'dark'
      ? {
          borderBottomColor: '#444',
          color: '#ccc',
          backgroundColor: '#333'
        }
      : {
          borderBottomColor: '#eee',
          color: '#333',
          backgroundColor: '#f7f7f7'
        }
)

const DesignerContainer = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const DesignerViewport = styled.div`
  position: absolute;
  top: 28px;
  left: 0;
  width: calc(100% - 0px);
  height: calc(100% - 28px);
`

const DesignerFloatingControls = styled.div<{ theme: string }>(
  {
    position: 'absolute',
    top: '50px',
    left: '10px',
    background: '#0e0e0e',
    'border-radius': '4px',
    border: '1px solid #555',
    padding: '0px',
    'z-index': 10,
    color: '#555',
    cursor: 'move'
  },
  ({ theme }) =>
    theme === 'dark'
      ? {
          background: '#333',
          borderColor: '#333',
          color: '#555'
        }
      : {
          background: '#f7f7f7',
          borderColor: '#ccc',
          color: '#ddd'
        }
)

const DesignerFloatingControlsHeader = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
`
const DesignerFloatingControlsBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: flex-start;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  color: #fff;
  padding: 8px 2px 4px 2px;
`

type DesignerProps = {
  data?: ViewportData
  onCreate?: (context: TNetworkDiagramContext) => void
}

/**
 * 네트워크 다이어그램 디자이너
 * @param param0
 * @returns
 */
export const Designer = forwardRef(
  ({ data = undefined, onCreate }: DesignerProps, ref: any) => {
    // 상위(Screen)에서 설정한 컨텍스트 에디터 전반에 영향을 준다.
    const networkDiesignerContext = useNetworkDiagram()
    const { context, setContext } = networkDiesignerContext
    const { data: contextData, selected, linkEdit } = context

    const handleCreateCanvas = ({ scene }: RootState) => {
      setScene(scene)
      onCreate?.(networkDiesignerContext)
    }

    const muiTheme = createTheme({
      palette: {
        mode: context.theme === 'dark' ? 'dark' : 'light'
      },
      typography: {
        fontFamily: [
          'Noto Sans KR',
          '-apple-system',
          'BlinkMacSystemFont',
          '"Segoe UI"',
          'Roboto',
          '"Helvetica Neue"',
          'Arial',
          'sans-serif',
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"'
        ].join(',')
        // fontSize: 12
      }
    })

    // APPBAR MENU THEME 설정
    useEffect(() => {
      if (context.theme === 'dark') {
        document.querySelectorAll('.szh-menu-container').forEach((el) => {
          el.classList.add('szh-menu-container--theme-dark')
        })
      } else {
        document.querySelectorAll('.szh-menu-container').forEach((el) => {
          el.classList.remove('szh-menu-container--theme-dark')
        })
      }
    }, [context.theme])

    const [scene, setScene] = useState<Scene | null>(null)

    useEffect(() => {
      if (data) {
        requestAnimationFrame(() => setContext({ state: 'CLEAN', data }))
      }
    }, [data])

    // THREE.js 엔진에 컨텍스트를 전달
    const ContextBridge = useContextBridge(NetworkDiagramContext)

    // 선택한 노드
    const [activeNode, setActiveNode] = useState<Object3D | undefined>(selected)
    useEffect(() => {
      if (!scene) {
        return
      }
      setActiveNode(undefined)
      if (!selected) {
        return
      }

      let object: Object3D<THREE.Event> | undefined
      if (typeof selected === 'string') {
        object = scene.getObjectByProperty('uuid', selected)
        if (object) {
          setContext({ selected: object })
        }
        return
      }

      if (!linkEdit) {
        if (selected?.name === 'NETWORK-DIAGRAM-NODE') {
          if (context.transform === 'translate') {
            setActiveNode(selected)
          } else {
            setActiveNode(
              selected?.getObjectByName('NETWORK-DIAGRAM-NODE-MODEL')
            )
          }
        } else {
          setActiveNode(selected)
        }
        // if (context.transform === 'translate') {
        //   setActiveNode(object)
        // } else {
        //   setActiveNode(object?.getObjectByName('NETWORK-DIAGRAM-NODE-MODEL'))
        // }
      }
    }, [scene, selected])

    const gridColor = context.theme === 'dark' ? '#fff' : '#000'
    const gridColor2 = context.theme === 'dark' ? '#444' : '#999'

    const mapControlsRef = useRef<MapControlsImpl>(undefined!)
    const transformControlsRef = useRef<TransformControlsImpl>(undefined!)

    // useEffect(() => {
    //   if (mapControlsRef.current) {
    //     // const controls = mapControlsRef.current
    //   }
    // }, [mapControlsRef])

    useEffect(() => {
      if (transformControlsRef.current) {
        const { current: controls } = transformControlsRef
        controls.addEventListener(
          'dragging-changed',
          onTransformControlsDraggingChangedCallback
        )
        return () => {
          transformControlsRef.current?.removeEventListener(
            'dragging-changed',
            onTransformControlsDraggingChangedCallback
          )
        }
      }
      return () => {}
    })

    const onTransformControlsDraggingChangedCallback = (
      e?: THREE.Event | undefined
    ) => {
      if (e?.value) {
        // 노드 편집 중 상태를 설정
        setContext({ transforming: true })
      } else {
        // 잠시 후 노드 편집 중 상태를 해제
        // <Select /> 와 이벤트가 겹쳐 핸재 편집 중인 노드를 버리고 이벤트 시점의 노드를 선택하는 현상 배제
        setTimeout(() => {
          setContext({ transforming: false })
        }, 500)
      }
    }

    const [translateBox, setTranslateBox] = useState<Box3 | null>(null)

    // 마우스 왼쪽 버튼을 눌렀을 때 이벤트 처리
    const handleTransformControlsMouseDown = () => {
      // if (context.transform === 'translate') {
      //   if (selected?.userData?.type === 'Plane') {
      //     const boundingBox = new Box3().setFromObject(
      //       selected as THREE.Object3D
      //     )
      //     boundingBox.min.setY(-1000)
      //     boundingBox.max.setY(1000)
      //     setTranslateBox(boundingBox)
      //   }
      // } else {
      //   setTranslateBox(null)
      // }
    }

    const handleTransformControlsMouseUp = () => {
      if (selected?.uuid) {
        const oldPosition = new Vector3().fromArray(
          contextData?.nodes?.[selected.uuid]?.position || []
        )
        const oldRotation = new Euler().fromArray(
          contextData?.nodes?.[selected.uuid]?.rotation || []
        )
        const oldScale = new Vector3().fromArray(
          contextData?.nodes?.[selected.uuid]?.size || []
        )

        if (selected?.name === 'NETWORK-DIAGRAM-NODE') {
          const model = selected.getObjectByName('NETWORK-DIAGRAM-NODE-MODEL')
          if (
            !selected?.position.equals(oldPosition) ||
            !model?.rotation.equals(oldRotation) ||
            !model?.scale.equals(oldScale)
          ) {
            const newData = {
              ...contextData
            }
            const newProps: NodeProps = {
              ...newData.nodes?.[selected.uuid],
              position: selected.position.toArray(),
              rotation: model?.rotation.toArray(),
              size: model?.scale.toArray()
            }
            newProps.rotation!.pop()

            // let effectedNodes: NodeProps[] = []
            // if (context.transform === 'translate' && translateBox) {
            //   // 평면 영역 내의 모든 노드에 위치를 변경한다.
            //   if (newData.nodes && translateBox) {
            //     effectedNodes = Object.values(newData.nodes).filter(
            //       (nodeProps) => {
            //         const obj = scene?.getObjectByProperty(
            //           'uuid',
            //           nodeProps.id!
            //         )
            //         if (obj) {
            //           const box = new Box3().setFromObject(obj)
            //           return translateBox.containsBox(box)
            //         }
            //         return false
            //       }
            //     )
            //   }
            // }

            const newNodes = { ...newData.nodes }
            newNodes[selected.uuid] = newProps
            // effectedNodes.forEach((node) => {
            //   newNodes[node.id!] = {
            //     ...node,
            //     position: new Vector3()
            //       .fromArray(node.position!)
            //       .add(activeNode!.position.clone().sub(oldPosition))
            //       .toArray()
            //     // rotation: newProps.rotation,
            //     // size: newProps.size
            //   }
            // })

            // 영향을 받는 모든 노드에 연결된 링크를 조회하여 패스를 수정한다.
            const effectedNodeIds = Object.keys(newNodes)
            // 노드의 위치가 변경된 링크의 앵글포인트를 초기화한다.
            // let newLinks: LinkProps[] = [
            //   ...resetPointsOnLinksByNodeId(state.links || [], selected.uuid)
            // ]
            let newLinks: LinkProps[] = [...(newData.links || [])]
            if (translateBox) {
              newLinks.map((link) => {
                if (
                  effectedNodeIds.includes(link.from!) ||
                  effectedNodeIds.includes(link.to!)
                ) {
                  link.points = getPointsFromArray(link.points || [])
                    .map((point) => {
                      if (translateBox.containsPoint(point)) {
                        point.add(activeNode!.position.clone().sub(oldPosition))
                      }
                      return point.toArray()
                    })
                    .flat()
                }
                return link
              })
            }

            setContext({
              state: context.state === 'NEW' ? 'NEW' : 'DIRTY',
              data: {
                ...newData,
                nodes: newNodes,
                links: newLinks
              }
            })
          }
        } else if (selected?.name === 'NETWORK-DIAGRAM-CHART') {
          const rotation = selected.rotation.toArray()
          rotation.pop()
          const newProps: ChartProps = {
            ...contextData!.userData!.charts?.find(
              (c: any) => c.id === selected.uuid
            )!,
            position: selected.position.toArray(),
            rotation: rotation,
            scale: selected.scale.toArray()
          }
          setContext({
            state: context.state === 'NEW' ? 'NEW' : 'DIRTY',
            data: {
              ...contextData,
              userData: {
                ...contextData!.userData,
                charts: [
                  ...contextData!.userData!.charts.filter(
                    (c: any) => c.id !== selected.uuid
                  ),
                  newProps
                ]
              }
            }
          })
        } else if (selected?.name === 'NETWORK-DIAGRAM-SHAPE') {
          const rotation = selected.rotation.toArray()
          rotation.pop()
          const newProps: ShapeProps = {
            ...contextData!.userData!.shapes?.find(
              (c: any) => c.id === selected.uuid
            )!,
            position: selected.position.toArray(),
            rotation: rotation,
            scale: selected.scale.toArray()
          }
          setContext({
            state: context.state === 'NEW' ? 'NEW' : 'DIRTY',
            data: {
              ...contextData,
              userData: {
                ...contextData!.userData,
                shapes: [
                  ...contextData!.userData!.shapes.filter(
                    (c: any) => c.id !== selected.uuid
                  ),
                  newProps
                ]
              }
            }
          })
        }
      }
    }

    // const [showYOnTransform, setShowYOnTransform] = useState<boolean>(true)

    /**
     * 마우스 클릭시 노드 모델을 선택한다.
     * @param selects 선택된 노드들
     * @returns
     */
    const handleSelectChanged = (selects: Object3D[]) => {
      setTranslateBox(null)
      setActiveNode(undefined)
      if (selects.length === 0) {
        setContext({ selected: null, linkEdit: false })
        return
      }

      if ((selects[0] as any)?.isLine2) {
        return
      }

      if (context.transforming || context.enableAddLinkControls) {
        return
      }

      setContext({ selected: getNode(selects[0]), linkEdit: false })
    }

    const handleLinkSelected = (id: string, selected: boolean) => {
      setActiveNode(undefined)
      if (selected) {
        const link = scene?.getObjectByProperty('uuid', id) as Group
        requestAnimationFrame(() => {
          setContext({ selected: link, linkEdit: true })
        })
      }
    }

    const handleLinkChanged = (id: string, data: any) => {
      setContext({
        data: {
          ...contextData,
          links: contextData!.links?.map((link) => {
            if (link.id === id) {
              return {
                ...link,
                ...data,
                userData: {
                  ...link.userData,
                  angleType: 'MANUAL'
                }
              }
            }
            return link
          })
        }
      })
    }

    const handleChangeTransformMode = (
      _: React.MouseEvent<HTMLElement, MouseEvent>,
      mode: 'translate' | 'rotate' | 'scale' = 'translate'
    ) => {
      // setShowYOnTransform(true)
      setContext({ transform: mode })
      if (linkEdit) {
        setActiveNode(undefined)
      } else {
        if (selected?.name === 'NETWORK-DIAGRAM-NODE') {
          if (mode === 'translate') {
            setActiveNode(selected)
          } else {
            setActiveNode(
              selected?.getObjectByName('NETWORK-DIAGRAM-NODE-MODEL')
            )
          }
        } else {
          setActiveNode(selected)
        }
      }
    }

    return (
      <ThemeProvider theme={muiTheme}>
        <CssBaseline />

        <DesignerContainer ref={ref}>
          <DesignerAppToolbar theme={context.theme || 'light'}>
            <Toolbar>
              <Hub
                fontSize='small'
                sx={{
                  display: { xs: 'none', md: 'flex' },
                  mr: 1
                  // fontSize: '12px'
                }}
              />
              <Typography
                variant='h6'
                noWrap
                component='a'
                href='#'
                sx={{
                  mr: 2,
                  display: { xs: 'none', md: 'flex' },
                  fontSize: '12px',
                  fontWeight: 400,
                  letterSpacing: '0',
                  color: 'inherit',
                  textDecoration: 'none'
                }}
              >
                Topology Designer
              </Typography>

              <Box
                component='div'
                sx={{ flexGrow: 0, flexShrink: 0, display: 'flex' }}
              >
                <FileMenu />
                <EditMenu />
                <ViewMenu />
              </Box>
            </Toolbar>
          </DesignerAppToolbar>

          <DesignerViewport>
            {context.editable && (
              <Draggable
                handle='.floating-controls'
                axis='both'
                bounds='parent'
              >
                <DesignerFloatingControls
                  theme={context.theme || 'light'}
                  className='floating-controls'
                >
                  <DesignerFloatingControlsHeader>
                    <DragHandleRoundedIcon
                      sx={{
                        fontSize: '0.7em',
                        lineHeight: '1em'
                      }}
                    />
                    <DragHandleRoundedIcon
                      sx={{
                        fontSize: '0.7em',
                        lineHeight: '1em'
                      }}
                    />
                    <DragHandleRoundedIcon
                      sx={{
                        fontSize: '0.7em',
                        lineHeight: '1em'
                      }}
                    />
                  </DesignerFloatingControlsHeader>

                  <DesignerFloatingControlsBox>
                    <ToggleButtonGroup
                      orientation='vertical'
                      exclusive
                      value={context.transform || 'translate'}
                      onChange={handleChangeTransformMode}
                    >
                      <ToggleButton value='translate' aria-label='이동'>
                        <OpenWithRoundedIcon />
                      </ToggleButton>
                      <ToggleButton value='rotate' aria-label='회전'>
                        <ThreeSixtyRoundedIcon />
                      </ToggleButton>
                      <ToggleButton value='scale' aria-label='크기'>
                        <PhotoSizeSelectSmallRoundedIcon />
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </DesignerFloatingControlsBox>
                </DesignerFloatingControls>
              </Draggable>
            )}
            {/* -------------------------------------------------------------------  */}
            {/* -------------------------------------------------------------------  */}
            {/* -------------------------------------------------------------------  */}
            {/* -------------------------------------------------------------------  */}
            {(!process.env.NODE_ENV ||
              process.env.NODE_ENV === 'development') && <Stats />}
            <Canvas
              gl={{
                antialias: true,
                alpha: true,
                powerPreference: 'high-performance'
              }}
              dpr={window.devicePixelRatio}
              orthographic
              camera={{
                position: [20, 20, 20],
                near: -1000,
                far: 1000,
                zoom: context.data?.userData?.zoom || 50
              }}
              onCreated={handleCreateCanvas}
            >
              <React.Suspense fallback={null}>
                <ContextBridge>
                  <directionalLight
                    position={[50, 200, 100]}
                    intensity={1.5}
                    shadow-mapSize-width={20000}
                    shadow-mapSize-height={20000}
                    shadow-camera-near={0.1}
                    shadow-camera-far={10000}
                    shadow-camera-top={3000}
                    shadow-camera-right={3000}
                    shadow-camera-bottom={-3000}
                    shadow-camera-left={-3000}
                    color='#ffffff'
                  />
                  <hemisphereLight
                    color='#4fa7e1'
                    groundColor='#cccccc'
                    position={[5, 10, -10]}
                    intensity={0.5}
                  />

                  <gridHelper
                    args={[1000, 2000, gridColor, gridColor2]}
                    position={[0, -1, 0]}
                    material-transparent={true}
                    material-opacity={context.theme === 'dark' ? 0.1 : 0.5}
                    // >
                    //   <lineBasicMaterial
                    //     attach='material'
                  />
                  {/* </gridHelper> */}

                  <MapControls
                    ref={mapControlsRef}
                    enabled={!context.transforming}
                    autoRotate={context.autoRotate}
                    autoRotateSpeed={
                      context.autoRotateSpeed !== undefined
                        ? context.autoRotateSpeed
                        : 0.2
                    }
                  />

                  {context.editable && activeNode && (
                    <TransformControls
                      // @ts-ignore
                      ref={transformControlsRef}
                      object={activeNode}
                      //
                      onMouseUp={handleTransformControlsMouseUp}
                      onMouseDown={handleTransformControlsMouseDown}
                      // 스냅 설정
                      translationSnap={0.01}
                      scaleSnap={0.01}
                      rotationSnap={0.01}
                      // 조작 모드
                      mode={context.transform || 'translate'}
                      // showY={showYOnTransform}
                    />
                  )}

                  {context.enableAddLinkControls && <AddLinkControls />}

                  <Select box onChange={handleSelectChanged}>
                    <group name='network-diagram-nodes'>
                      {contextData?.nodes &&
                        Object.values(contextData.nodes).map((node) => (
                          <Node key={node.id} {...node} />
                        ))}
                    </group>

                    <group name='network-diagram-shapes'>
                      {contextData?.userData?.shapes &&
                        (contextData.userData.shapes || []).map(
                          (shape: any) => <Shape key={shape.id} {...shape} />
                        )}
                    </group>

                    <group name='network-diagram-charts'>
                      {contextData?.userData?.charts &&
                        (contextData.userData.charts || []).map(
                          (chart: any) => <Chart key={chart.id} {...chart} />
                        )}
                    </group>

                    <group name='network-diagram-links'>
                      {contextData?.links &&
                        contextData.links.map((link) => (
                          <Link
                            key={link.id}
                            {...link}
                            userData={link.userData}
                            editable={context.editable}
                            mapControls={mapControlsRef}
                            onSelected={handleLinkSelected}
                            onChanged={handleLinkChanged}
                          />
                        ))}
                    </group>
                  </Select>
                </ContextBridge>
              </React.Suspense>
            </Canvas>
            {/* -------------------------------------------------------------------  */}
            {/* -------------------------------------------------------------------  */}
            {/* -------------------------------------------------------------------  */}
            {/* -------------------------------------------------------------------  */}
          </DesignerViewport>
        </DesignerContainer>
      </ThemeProvider>
    )
  }
)
