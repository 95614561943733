import React, { useState } from 'react'
import { ColorRepresentation } from 'three'
import { LinkProps, NodeProps, ViewportData } from '../components'
import { Traffic } from '../components/link/Traffics'
import { TNodeTypes } from '../components/node/NodeTypes'
import { UUID } from '../utils'
import {
  NetworkDiagramContext,
  NetworkDiagramDefaultContext,
  NetworkDiagramState
} from './NetworkDiagramContext'

type NetworkDiagramProviderProps = {
  value: NetworkDiagramState
  children?: React.ReactNode
  onSubmit?: (
    data: ViewportData,
    mode: 'UPDATE' | 'DELETE' | 'CREATE'
  ) => Promise<ViewportData>
  onOpenFile?: () => void
  onSelectChange?: (selected: any) => void
}

export const NetworkDiagramProvider = ({
  value,
  onSubmit = () => {
    process.env.NODE_ENV === 'development' &&
      console.log('onSubmit is not provided')
    return Promise.resolve(value)
  },
  onOpenFile = () => {
    process.env.NODE_ENV === 'development' &&
      console.log('onOpenFile is not provided')
  },
  onSelectChange = () => {
    process.env.NODE_ENV === 'development' &&
      console.log('onSelectChange is not provided')
  },
  children
}: NetworkDiagramProviderProps) => {
  // FILE MENU --------------------------------------------------
  // ============================================================================
  // OPEN FILE ----------------------------------
  const openFile = () => {
    onOpenFile()
  }

  const setFile = (data: ViewportData) => {
    setContext({
      selected: undefined,
      linkEdit: false,
      editable: !data?.readonly,
      data: { ...data },
      state: 'CLEAN'
    })
  }

  // EMPTY AS NEW -------------------------------
  const createDiagram = (name: string) => {
    setContext({
      selected: undefined,
      linkEdit: false,
      editable: true,
      data: {
        id: UUID.generate(),
        name: name,
        nodes: {},
        links: [],
        readonly: false,
        published: false
      },
      state: 'NEW'
    })
  }

  // CREATE NEW -------------------------------
  const saveDiagramAsNew = async (name: string) => {
    let links: LinkProps[] =
      storedState.data?.links?.map((l) => ({ ...l, id: UUID.generate() })) || []

    const nodes: { [id: string]: NodeProps } = {}

    for (let id in storedState.data?.nodes) {
      const nid = UUID.generate()
      nodes[nid] = { ...storedState.data?.nodes[id], id: nid }
      links.forEach((link) => {
        if (link.from === id) {
          link.from = nid
        }
        if (link.to === id) {
          link.to = nid
        }
      })
    }

    const storedData = await onSubmit(
      {
        ...storedState.data,
        id: UUID.generate(),
        name: name,
        readonly: false,
        published: false,
        nodes,
        links
      },
      'CREATE'
    )

    setContext({ data: storedData, editable: true, state: 'CLEAN' })
    return Promise.resolve(storedData)
  }

  // UPDATE -------------------------------
  const saveDiagram = async () => {
    const storedData = await onSubmit(
      { ...storedState.data },
      storedState.state === 'NEW' ? 'CREATE' : 'UPDATE'
    )
    setContext({ data: storedData, state: 'CLEAN' })
    return Promise.resolve(storedData)
  }

  // DELETE -------------------------------
  const deleteDiagram = async () => {
    await onSubmit({ ...storedState.data }, 'DELETE')
    setContext({ data: {}, state: 'CLEAN' })
    return Promise.resolve({})
  }

  // EDIT -----------------------------------------------------------------------------
  // ==================================================================================
  // ADD NODE -------------------------------
  const addNode = (
    name: string,
    type: TNodeTypes,
    subtype: string | undefined
  ) => {
    const newNodeId = UUID.generate()
    setContext({
      data: {
        ...storedState.data,
        nodes: {
          ...(storedState.data?.nodes || {}),
          [newNodeId]: {
            id: newNodeId,
            name: name,
            position: [0, 0, 0],
            rotation: [0, 0, 0],
            size: [1, 1, 1],
            type: type,
            color: '#3377ff',
            userData: {
              subtype: subtype
            }
          }
        }
      },
      selected: newNodeId,
      state: storedState.state === 'NEW' ? 'NEW' : 'DIRTY'
    })
  }

  // SAVE NODE -------------------------------
  const saveNode = (node: NodeProps) => {
    const updated = {
      ...storedState.data?.nodes?.[node.id!],
      ...node
    }
    setContext({
      data: {
        ...storedState.data,
        nodes: { ...(storedState.data?.nodes || {}), [updated.id!]: updated }
      },
      state: storedState.state === 'NEW' ? 'NEW' : 'DIRTY'
    })
  }

  // ADD LINK -------------------------------
  const addLink = (name: string, from: string, to: string) => {
    setContext({
      data: {
        ...storedState.data,
        links: [
          ...(storedState.data?.links || []),
          {
            id: UUID.generate(),
            name: name,
            from: from,
            to: to,
            points: [],
            color: '#ff0',
            type: 'SOLID',
            width: 1,
            state: 'NEW',
            userData: {}
          }
        ]
      },
      state: storedState.state === 'NEW' ? 'NEW' : 'DIRTY'
    })
  }
  // SAVE LINK -------------------------------
  const saveLink = (link: LinkProps) => {
    setContext({
      data: {
        ...storedState.data,
        links: [...(storedState.data?.links || [])].map((l) => {
          if (l.id === link.id) {
            return { ...l, ...link }
          }
          return l
        })
      },
      state: storedState.state === 'NEW' ? 'NEW' : 'DIRTY'
    })
  }

  // DELETE -------------------------------
  const deleteSelected = (selected: any) => {
    const newData = {
      ...storedState.data,
      links: [...(storedState.data!.links || [])].filter(
        (link) =>
          link.id !== selected.uuid &&
          link.from !== selected.uuid &&
          link.to !== selected.uuid
      )
    }
    // newData.nodes?.[selected.uuid] &&
    // delete newData.nodes?.[selected.uuid]
    const nodes: { [key: string]: NodeProps } = {}
    Object.keys(newData.nodes || {}).forEach((id) => {
      if (id !== selected.uuid) {
        nodes[id] = newData.nodes![id]
      }
    })
    newData.nodes = nodes

    setContext({
      selected: undefined,
      linkEdit: false,
      data: newData,
      state: storedState.state === 'NEW' ? 'NEW' : 'DIRTY'
    })
  }

  const [storedState, setStoredState] = useState<NetworkDiagramState>({
    theme: 'light',
    editable: false,
    transforming: false,
    transform: 'translate',
    linkMarkerColors: ['#fff', '#0ff', '#f0f', '#ff0', '#0f0', '#f00', '#000'],
    data: {},
    ...value
  })

  // const history = []

  const setContext = (newContext: NetworkDiagramState) => {
    if (storedState.selected !== newContext.selected) {
      onSelectChange && onSelectChange(newContext.selected)
    }

    // process.env.NODE_ENV === 'development' &&
    //   console.log('NEW CONTEXT DATA', newContext)

    // NOTE 직전까지의 변경이 적용될 수 있도록 함수형으로 구현해야한다.
    setStoredState((prevState) => {
      const context = {
        ...prevState,
        ...newContext
      }
      return context
    })
  }

  const addAlarmToNode = (
    id: string,
    alarm: 'power' | 'powers' | 'temperature' | 'service' | 'breakdown'
  ) => {
    saveNode({
      ...storedState.data?.nodes?.[id],
      userData: {
        ...storedState.data?.nodes?.[id]?.userData,
        error: alarm
      }
    })
  }

  const removeAlarmFromNode = (id: string) => {
    saveNode({
      ...storedState.data?.nodes?.[id],
      userData: {
        ...storedState.data?.nodes?.[id]?.userData,
        error: false
      }
    })
  }

  const addTrafficToLink = (id: string, traffic: Traffic) => {
    const link = storedState.data?.links?.find((l) => l.id === id)
    if (link) {
      saveLink({
        ...link,
        userData: {
          ...link.userData,
          traffics: [...(link.userData?.traffics || []), traffic]
        }
      })
    }
  }

  const removeTrafficFromLink = (id: string, traffic: Traffic) => {
    const link = storedState.data?.links?.find((l) => l.id === id)
    if (link) {
      saveLink({
        ...link,
        userData: {
          ...link.userData,
          traffics: (link.userData?.traffics || []).filter(
            (t: Traffic) => t.id !== traffic.id
          )
        }
      })
    }
  }

  const clearTrafficsFromLink = (id: string) => {
    const link = storedState.data?.links?.find((l) => l.id === id)
    if (link) {
      saveLink({
        ...link,
        userData: {
          ...link.userData,
          traffics: []
        }
      })
    }
  }

  const setLinkColor = (id: string, color: ColorRepresentation) => {
    const link = storedState.data?.links?.find((l) => l.id === id)
    if (link) {
      saveLink({
        ...link,
        color: color
      })
    }
  }

  const setFrameLoop = (
    frameloop: 'always' | 'demand' | 'never' | undefined = 'always'
  ) => {
    setContext({
      frameloop: frameloop
    })
  }

  const undo = () => {
    console.log('UNDO')
  }
  const redo = () => {
    console.log('REDO')
  }

  const setDescriptionToNodeByName = (name: string, description: string) => {
    const node = Object.values(storedState.data?.nodes || {}).find(
      (n) => n.name === name
    )
    console.log('node', name,  node, description)
    if (node) {
      saveNode({
        ...node,
        userData: {
          ...node.userData,
          description: description
        }
      })
    }
  }

  return (
    <NetworkDiagramContext.Provider
      value={{
        ...NetworkDiagramDefaultContext,
        context: storedState,
        setContext,
        setFrameLoop,
        setFile,
        // FILE MENU //////////////////////////////////////////////////////////////////////////////
        openFile,
        createDiagram,
        saveDiagram,
        saveDiagramAsNew,
        deleteDiagram,
        // EDIT MENU //////////////////////////////////////////////////////////////////////////////
        addNode,
        saveNode,
        addLink,
        saveLink,
        deleteSelected,
        setDescriptionToNodeByName,
        undo,
        redo,
        // --------------------------------------------------------------------------------
        addAlarmToNode,
        removeAlarmFromNode,
        addTrafficToLink,
        removeTrafficFromLink,
        clearTrafficsFromLink,
        setLinkColor
        // VIEW MENU //////////////////////////////////////////////////////////////////////////////
      }}
    >
      {children}
    </NetworkDiagramContext.Provider>
  )
}
