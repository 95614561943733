import { Plane } from '@react-three/drei'
import React, { forwardRef } from 'react'
import { DoubleSide } from 'three'
import { IModelProps } from '../node/Model'

const Model = forwardRef((props: IModelProps, ref: any) => {
  const {
    name,
    position = [0, 0, 0],
    rotation = [0, 0, 0],
    color = 0xffffff,
    scale = [1, 1, 1],
    ...rest
  } = props

  return (
    <group
      ref={ref}
      scale={scale}
      rotation={rotation}
      name='NETWORK-DIAGRAM-NODE-MODEL'
    >
      <Plane
        args={[1, 1, 1, 1]}
        {...rest}
        rotation={[-Math.PI / 2, 0, 0]}
        material-color={color}
        material-side={DoubleSide}
        material-transparent={true}
        material-opacity={0.2}
      />
    </group>
  )
})

export default Model
