import { useThree } from '@react-three/fiber'
import { useEffect } from 'react'
import { Raycaster } from 'three'
import { getNode } from './nodeUtils'

type IntersectObjectProps = {
  onClick?: (object: { [key: string]: any }) => void
}

export const IntersectObject = ({ onClick }: IntersectObjectProps) => {
  const three = useThree()
  const { gl, camera, scene, mouse } = three
  const raycaster = new Raycaster()

  useEffect(() => {
    const handleOnClick = () => {
      raycaster.setFromCamera(mouse, camera)
      const intersects = raycaster.intersectObjects(scene.children, true)
      onClick?.(getNode(intersects[0].object)?.userData!)
    }

    // @ts-ignore
    gl.domElement.addEventListener('click', handleOnClick, false)

    return () => {
      // @ts-ignore
      gl.domElement.removeEventListener('click', handleOnClick, false)
    }
  }, [])
  return null
}
