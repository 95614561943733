import { a, useChain, useSpring, useSpringRef } from '@react-spring/three'
import { Text } from '@react-three/drei'
import React, { forwardRef } from 'react'
import { DoubleSide } from 'three'

import { IModelProps } from '../../../node/Model'
import { Arc } from '../Arc'

const Model = forwardRef((props: IModelProps, ref: any) => {
  const { outerRotate } = useSpring({
    loop: true,
    from: { outerRotate: 0 },
    to: { outerRotate: -Math.PI * 2 },
    config: { duration: 3000 }
  })

  const innerRef = useSpringRef()
  const { innerRotate } = useSpring({
    ref: innerRef,
    config: { duration: 1000 },
    loop: true,
    from: { innerRotate: 0 },
    to: { innerRotate: -Math.PI * 2 }
  })
  const innerRef2 = useSpringRef()
  const { innerRotate2 } = useSpring({
    ref: innerRef2,
    config: { duration: 500 },
    loop: { reverse: true },
    from: { innerRotate2: -Math.PI * 2 },
    to: { innerRotate2: -Math.PI }
  })

  useChain([innerRef, innerRef2], [0, 0.5])

  return (
    <group
      name='NETWORK-DIAGRAM-CHART'
      ref={ref}
      position={props.position}
      rotation={[-Math.PI / 2, 0, 0]}
      scale={props.scale}
    >
      <mesh>
        <circleGeometry attach='geometry' args={[0.1, 32]} />
        <meshBasicMaterial
          attach='material'
          color={'#1f91be'}
          side={DoubleSide}
        />
      </mesh>

      <a.group name='inner' rotation-z={innerRotate}>
        <Arc
          aRadius={0.6}
          aRadius2={0.55}
          aStartAngle={0}
          aEndAngle={180}
          color={'#1f91be'}
          aClockwise={true}
          animate={false}
        />
        <a.group name='inner' rotation-z={innerRotate2}>
          <Arc
            aRadius={0.6}
            aRadius2={0.55}
            aStartAngle={180}
            aEndAngle={340}
            color={'#1f91be'}
            aClockwise={true}
            animate={false}
          />
        </a.group>
      </a.group>

      <a.group name='outer' rotation-z={outerRotate}>
        <Arc
          aRadius={1}
          aRadius2={0.8}
          aStartAngle={-20}
          aEndAngle={20}
          color={'#1f91be'}
        />
        <Arc
          aRadius={1}
          aRadius2={0.8}
          aStartAngle={55}
          aEndAngle={95}
          color={'#1f91be'}
        />
        <Arc
          aRadius={1}
          aRadius2={0.8}
          aStartAngle={135}
          aEndAngle={175}
          color={'#1f91be'}
        />
        <Arc
          aRadius={1}
          aRadius2={0.8}
          aStartAngle={185}
          aEndAngle={225}
          color={'#1f91be'}
        />
        <Arc
          aRadius={1}
          aRadius2={0.8}
          aStartAngle={265}
          aEndAngle={305}
          color={'#1f91be'}
        />
      </a.group>

      <Text
        color={0xffffff}
        fontSize={0.2}
        letterSpacing={0}
        textAlign={'center'}
        anchorX={'center'}
        anchorY={'middle'}
        font={`${
          process.env.REACT_APP_BASE_URL || ''
        }assets/fonts/KTfontBold.ttf`}
        material-depthTest={false}
        material-depthWrite={false}
        outlineWidth={'15%'}
        outlineColor='#0f4e9f'
        outlineOpacity={0}
      >
        Encoder
      </Text>
    </group>
  )
})

export default Model
