import { useGLTF } from '@react-three/drei'
import React, { forwardRef, useState } from 'react'
import { IModelProps } from '../node/Model'

const modelSource = '/assets/models/transcoder.gltf'
useGLTF.preload(modelSource)

const Model = forwardRef((props: IModelProps, ref: any) => {
  const gltf = useGLTF(modelSource) as any

  const [scene] = useState<THREE.Scene>(gltf.scene.clone())
  scene.userData = props.userData || {}

  return (
    <group
      ref={ref}
      scale={props.scale}
      rotation={props.rotation}
      name='NETWORK-DIAGRAM-NODE-MODEL'
    >
      <primitive object={scene} />
    </group>
  )
})

export default Model
