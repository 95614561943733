import { Vector3 } from 'three'
// import { linkY } from '../components/config'

/**
 * 꺽은 선 표시를 위한 중간 점 추가
 *
 * @param from 시작 노드
 * @param to 종료 노드
 * @returns
 */
export function getAnglePoints(from: Vector3, to: Vector3) {
  const points: Vector3[] = []

  // 기본값으로 중간 지점에 꺽은 점을 추가
  const dx = Math.abs(from.x - to.x)
  if (dx > 0) {
    const x = from.x - (from.x - to.x) / 2
    // points.push(new Vector3(x, linkY, from.z))
    // points.push(new Vector3(x, linkY, to.z))
    points.push(new Vector3(x, from.y, from.z))
    points.push(new Vector3(x, to.y, to.z))
  }

  return points
}
