import { MeshProps, useLoader } from '@react-three/fiber'
import React from 'react'
import { Color, ColorRepresentation } from 'three'
import { TextGeometryParameters } from 'three-stdlib'
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader'

declare type Text3DProps = {
  fontWeight?:
    | 100
    | 'Thin'
    | 300
    | 'Light'
    | 400
    | 'Medium'
    | 500
    | 'Regular'
    | 700
    | 'Bold'
    | 900
    | 'Bold'
  color?: ColorRepresentation
} & Omit<TextGeometryParameters, 'font'> &
  MeshProps

export const Text3D = React.forwardRef<
  THREE.Object3D<THREE.Event> | null,
  React.PropsWithChildren<Text3DProps>
>(({ fontWeight, position, rotation, children, color }, ref) => {
  const flatColor = new Color(color || '#ffffff') //.setScalar(1.5)
  const sideColor = flatColor
    .clone()
    .lerpColors(new Color('#ffffff'), new Color('#0000ff'), 0.75)

  let fontName = 'assets/fonts/KT-Bold.json'
  switch (fontWeight) {
    case 100:
    case 'Thin':
      fontName = 'assets/fonts/KT-Thin.json'
      break
    case 500:
    case 'Regular':
      fontName = 'assets/fonts/KT-Light.json'
      break
    case 700:
    case 'Bold':
      fontName = 'assets/fonts/KT-Medium.json'
      break
    case 900:
    case 'Bold':
      fontName = 'assets/fonts/KT-Bold.json'
      break
    default:
      fontName = 'assets/fonts/KT-Light.json'
      break
  }
  const font = useLoader(FontLoader, fontName)

  const configs = React.useMemo(
    () => [
      { font, size: 1, height: 0.5, curveSegments: 12 },
      { font, size: 1, height: 0.001, curveSegments: 12 }
    ],
    [font]
  )

  const mesh = React.useRef<THREE.Mesh>()
  const flat = React.useRef<THREE.Mesh>()

  React.useLayoutEffect(() => {
    if (mesh.current && flat.current) {
      const geoMesh = new TextGeometry(children as string, configs[0])
      mesh.current.geometry = geoMesh

      const geoFlat = new TextGeometry(children as string, configs[1])
      flat.current.geometry = geoFlat
    }
  }, [mesh, flat, children])

  return (
    <group
      ref={ref}
      name='NETWORK-DIAGRAM-SHAPE--TEXT3D'
      position={position}
      rotation={rotation}
    >
      <mesh ref={mesh} rotation={[-Math.PI / 2, 0, Math.PI / 2]}>
        <meshStandardMaterial color={sideColor} />
        <mesh ref={flat} position={[0, 0, 0.5]}>
          <meshStandardMaterial color={flatColor} />
        </mesh>
      </mesh>
    </group>
  )
})
