import {
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  SubMenu
} from '@szhsin/react-menu'
import '@szhsin/react-menu/dist/index.css'

import React, { Fragment, useState } from 'react'
import { useNetworkDiagram } from '../../../contexts'

import { TNodeServerSubTypes, TNodeTypes } from '../../node/NodeTypes'

import { DeleteDialog } from './edit/DeleteDialog'

export type NodeTypes = TNodeTypes | ''
export type NodeSubTypes = TNodeServerSubTypes | ''

type Props = {}
export default function EditMenu({}: Props) {
  const [openedDialog, setOpenedDialog] = useState<
    'addlink' | 'addnode' | 'delete' | null
  >(null)

  const { context, deleteSelected, addNode, setContext } = useNetworkDiagram()

  const disabled = context.editable !== true

  const handleOnClickAddNodeMenu = (
    type: TNodeTypes,
    subtype?: NodeSubTypes
  ) => {
    addNode('Untitled', type, subtype)
  }

  const handleOnClickAddLinkMenu = () => {
    setContext({
      enableAddLinkControls: true,
      selected: undefined
    })
  }

  const handleOnClickDeleteSelectedMenu = () => {
    setOpenedDialog('delete')
  }

  const closeDialog = () => {
    requestAnimationFrame(() => {
      setOpenedDialog(null)
    })
  }

  // HANDLERS --------------------------------------------------
  const handleOnSubmitDeleteDialog = () => {
    deleteSelected(context.selected)
    closeDialog()
  }

  const handleOnCloseDeleteDialog = () => {
    closeDialog()
  }

  // const onUndo = () => {
  //   undo()
  // }

  // const onRedo = () => {
  //   redo()
  // }

  // prettier-ignore
  return (
    <Fragment>
      <Menu
        menuButton={
          <MenuButton
            style={{
              background: 'transparent',
              color: context.theme === 'dark' ? '#fff' : '#000',
              border: 0,
              marginLeft: '20px'
            }}
          >편집</MenuButton>
        }
        theming={'dark'}
      >
        {/* <MenuItem disabled={disabled} onClick={onUndo}>Undo</MenuItem>
        <MenuItem disabled={disabled} onClick={onRedo}>Redo</MenuItem>
        <MenuDivider /> */}

        <SubMenu label='장비 추가' disabled={disabled}>
          <SubMenu label='서버'>
            <MenuItem onClick={() => handleOnClickAddNodeMenu('Server', 'dvb')}>DVB</MenuItem>
            <MenuItem onClick={() => handleOnClickAddNodeMenu('Server', 'cas')}>CAS</MenuItem>
            <MenuItem onClick={() => handleOnClickAddNodeMenu('Server', 'etc')}>Etc</MenuItem>
          </SubMenu>
          <MenuItem onClick={() => handleOnClickAddNodeMenu('MUX')}>IP MUX</MenuItem>
          <MenuItem onClick={() => handleOnClickAddNodeMenu('IPAcoMux')}>IP ACO MUX</MenuItem>
          <MenuItem onClick={() => handleOnClickAddNodeMenu('MSPP')}>MSPP</MenuItem>
          <MenuItem onClick={() => handleOnClickAddNodeMenu('L3')}>L3</MenuItem>
          <MenuItem onClick={() => handleOnClickAddNodeMenu('Encoder')}>인코더</MenuItem>
          <MenuItem onClick={() => handleOnClickAddNodeMenu('Transcoder')}>트랜스코더</MenuItem>
          <MenuItem onClick={() => handleOnClickAddNodeMenu('Transmission')}>전송망</MenuItem>

          <MenuItem onClick={() => handleOnClickAddNodeMenu('Seezn')}>씨즌</MenuItem>

          <MenuItem onClick={() => handleOnClickAddNodeMenu('Typography')}>텍스트 2D</MenuItem>
          <MenuItem onClick={() => handleOnClickAddNodeMenu('Skylife')}>Skylife</MenuItem>
          <MenuItem onClick={() => handleOnClickAddNodeMenu('Aws')}>AWS</MenuItem>
          
          <MenuItem onClick={() => handleOnClickAddNodeMenu('Rect')}> 평면 </MenuItem>
          <MenuItem onClick={() => handleOnClickAddNodeMenu('Plane')}> 평면(원뷰) </MenuItem>

            {/*
          <SubMenu label='OneView'>
            <SubMenu label='3D 글자'>
              <MenuItem onClick={() => handleOnClickAddNodeMenu('TEXT_3D_BAECSEOK')}>백석</MenuItem>
              <MenuItem onClick={() => handleOnClickAddNodeMenu('TEXT_3D_YEOUIDO')}>여의도</MenuItem>
              <MenuItem onClick={() => handleOnClickAddNodeMenu('TEXT_3D_LGUPLUS')}>LGU+</MenuItem>
              <MenuItem onClick={() => handleOnClickAddNodeMenu('TEXT_3D_GROUNDWAVE')}>지상파</MenuItem>
              <MenuItem onClick={() => handleOnClickAddNodeMenu('TEXT_3D_PP')}>PP</MenuItem>
              <MenuItem onClick={() => handleOnClickAddNodeMenu('TEXT_3D_CUG')}>CUG</MenuItem>
              <MenuItem onClick={() => handleOnClickAddNodeMenu('TEXT_3D_SATELLITE')}>위성</MenuItem>
              <MenuItem onClick={() => handleOnClickAddNodeMenu('TEXT_3D_AWS')}>AWS</MenuItem>
            </SubMenu>

            <SubMenu label='OneView 차트'>
              <MenuItem onClick={() => handleOnClickAddNodeMenu('CHART_3D_PLANE')}>평면</MenuItem>
              <MenuItem onClick={() => handleOnClickAddNodeMenu('CHART_3D_SYLINDER')}>실린더 차트</MenuItem>
              <MenuItem onClick={() => handleOnClickAddNodeMenu('CHART_3D_BLOCK')}>블록형 차트</MenuItem>
              <MenuItem onClick={() => handleOnClickAddNodeMenu('CHART_3D_SWITCH')}>스위치 차트</MenuItem>
              <MenuItem onClick={() => handleOnClickAddNodeMenu('CHART_3D_IPACO')}>IP ACO 차트</MenuItem>
              <MenuItem onClick={() => handleOnClickAddNodeMenu('CHART_3D_MUX')}>MUX 차트</MenuItem>
              <MenuItem onClick={() => handleOnClickAddNodeMenu('CHART_3D_ENCODER')}>Encoder 차트</MenuItem>
            </SubMenu>
          </SubMenu>
          */}
          
          <MenuItem onClick={() => handleOnClickAddNodeMenu('Unknown')}>기타</MenuItem>

        </SubMenu>

        <MenuItem onClick={handleOnClickAddLinkMenu} disabled={disabled}>링크 추가</MenuItem>

        <MenuDivider />

        <MenuItem
          disabled={disabled || !context.selected}
          onClick={handleOnClickDeleteSelectedMenu}
        >
          삭제
        </MenuItem>
      </Menu>

      {/* DELETE Node or Link */}
      <DeleteDialog
        isOpen={openedDialog === 'delete'}
        onSubmit={handleOnSubmitDeleteDialog}
        onClose={handleOnCloseDeleteDialog}
      />
    </Fragment>
  )
}
