import React from 'react'

import { useGLTF } from '@react-three/drei'
import { GroupProps } from '@react-three/fiber'
import { Color, MeshStandardMaterial } from 'three'

const modelSource = '/assets/models/chart-cylinder.gltf'
useGLTF.preload(modelSource)

type CylinderProps = GroupProps & {
  value: number
  color: THREE.ColorRepresentation
}

export const Cylinder = ({
  value,
  color = 0x33aaff,
  ...props
}: CylinderProps) => {
  const {
    nodes: {
      Cylinder: { geometry }
    }
  } = useGLTF(modelSource) as any

  const [material0, material1] = React.useMemo(() => {
    const _color = new Color(color)
    _color.convertSRGBToLinear()
    const mat0 = new MeshStandardMaterial({
      color: _color,
      transparent: true,
      opacity: 0.25
    })
    const mat1 = mat0.clone()
    // mat0.color.addScalar(-0.5)
    mat1.opacity = 1
    return [mat0, mat1]
  }, [color])

  React.useEffect(() => {
    return () => {
      geometry.dispose()
      material0.dispose()
      material1.dispose()
    }
  }, [])

  return (
    <group {...props} name='Cylinder'>
      <mesh
        geometry={geometry}
        material={value > 0 ? material1 : material0}
        position={[0, 0, 0]}
      />
      <mesh
        geometry={geometry}
        material={value > 1 ? material1 : material0}
        position={[0, 0.4, 0]}
      />
      <mesh
        geometry={geometry}
        material={value > 2 ? material1 : material0}
        position={[0, 0.8, 0]}
      />
      <mesh
        geometry={geometry}
        material={value > 3 ? material1 : material0}
        position={[0, 1.2, 0]}
      />
      <mesh
        geometry={geometry}
        material={value > 4 ? material1 : material0}
        position={[0, 1.6, 0]}
      />
      <mesh
        geometry={geometry}
        material={value > 5 ? material1 : material0}
        position={[0, 2.0, 0]}
      />
      <mesh
        geometry={geometry}
        material={value > 6 ? material1 : material0}
        position={[0, 2.4, 0]}
      />
      <mesh
        geometry={geometry}
        material={value > 7 ? material1 : material0}
        position={[0, 2.8, 0]}
      />
      <mesh
        geometry={geometry}
        material={value > 8 ? material1 : material0}
        position={[0, 3.2, 0]}
      />
      <mesh
        geometry={geometry}
        material={value > 9 ? material1 : material0}
        position={[0, 3.6, 0]}
      />
    </group>
  )
}
