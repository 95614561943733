import { GroupProps } from '@react-three/fiber'
import React from 'react'
import { ChartBottomLeft } from './2d'
import { ChartBottomRight1 } from './3d'
import ChannelBlockChart from './3d/ChannelBlockChart'
import EncoderChart from './3d/EncoderChart'
import IPAcoChart from './3d/IPAcoChart'
import MuxChart from './3d/MuxChart'
import SwitchChart from './3d/SwitchChart'

export type ChartProps = GroupProps & {
  id?: string
  chart?: string
  value?: number | string | (string | number)[]
  color?: THREE.ColorRepresentation | THREE.ColorRepresentation[]
  update?: () =>
    | number
    | string
    | (string | number)[]
    | Promise<number | string | (string | number)[]>
}

export default ({ id, chart, value, color, update, ...props }: ChartProps) => {
  // prettier-ignore
  return (
    <group uuid={id} {...props} name='NETWORK-DIAGRAM-CHART' userData={props.userData}>
      {chart === 'BottomLeft'   && <ChartBottomLeft   value={value} color={color} update={update}/>}
      {chart === 'BottomRight1' && <ChartBottomRight1 value={value} color={color} update={update}/>}
      {chart === 'BottomRight2' && <ChartBottomRight1 value={value} color={color} update={update}/>}
      {chart === 'ChannelBlock' && <ChannelBlockChart value={value} color={color} update={update}/>}
      {chart === 'Switch'       && <SwitchChart       value={value} color={color} update={update}/>}
      {chart === 'IPAco'        && <IPAcoChart        value={value} color={color} update={update}/>}
      {chart === 'Mux'          && <MuxChart          value={value} color={color} update={update}/>}
      {chart === 'Encoder'      && <EncoderChart      value={value} color={color} update={update}/>}
    </group>
  )
}
