import { Box, TransformControls } from '@react-three/drei'
import React, { useEffect, useRef, useState } from 'react'
import { Object3D, Vector3 } from 'three'
import {
  MapControls,
  TransformControls as TransformControlsImpl
} from 'three-stdlib'

type LinkTransformControllerProps = {
  points: Vector3[]
  mapControls?: React.MutableRefObject<MapControls>
  onChange: (points: Vector3[]) => void
}

export const LinkTransformController = ({
  points = [],
  mapControls,
  onChange
}: LinkTransformControllerProps) => {
  const onChangePoint = (index: number, position: Vector3) => {
    const newPoints = [...points]
    newPoints.splice(index, 1, position)
    onChange(newPoints)
  }

  const onRemoveAnglePoint = (index: number) => {
    const newPoints = [...points]
    newPoints.splice(index, 1)
    onChange(newPoints)
  }

  return (
    <group>
      {points.map((point, index) => (
        <AnglePoint
          key={index}
          position={point}
          mapControls={mapControls}
          onChange={(position: Vector3) => onChangePoint(index, position)}
          onContextMenu={() => onRemoveAnglePoint(index)}
        />
      ))}
    </group>
  )
}

type AnglePointProps = {
  position: Vector3
  mapControls?: React.MutableRefObject<MapControls>
  onChange: (position: Vector3) => void
  onContextMenu: () => void
}

const AnglePoint = ({
  position,
  mapControls,
  onChange,
  onContextMenu
}: AnglePointProps) => {
  const ref = useRef<Object3D>()
  const [active, setActive] = useState<Object3D | undefined>(undefined)
  const [transforming, setTransforming] = useState<boolean>(false)

  const onClick = () => {
    setActive(ref.current)
  }

  const onPointerMissed = () => {
    if (!transforming) {
      setActive(undefined)
    }
  }

  const onTransformControlsMouseMove = () => {
    // console.log('onTransformControlsMouseMove')
    active && onChange(active.position)
  }

  const transformControlsRef = useRef<TransformControlsImpl>(undefined!)
  const onTransformControlsDraggingChangedCallback = (
    e?: THREE.Event | undefined
  ) => {
    // console.log('onTransformControlsDraggingChangedCallback')
    if (e?.value) {
      // 노드 편집 중 상태를 설정
      setTransforming(true)
      mapControls && (mapControls.current.enabled = false)
      active && onChange(active.position)
    } else {
      // 잠시 후 노드 편집 중 상태를 해제
      // <Select /> 와 이벤트가 겹쳐 핸재 편집 중인 노드를 버리고 이벤트 시점의 노드를 선택하는 현상 배제
      setTimeout(() => {
        setTransforming(false)
        mapControls && (mapControls.current.enabled = true)
      }, 300)
    }
  }

  useEffect(() => {
    if (transformControlsRef?.current) {
      const controls = transformControlsRef.current
      controls.addEventListener(
        'dragging-changed',
        onTransformControlsDraggingChangedCallback
      )
      return () => {
        transformControlsRef?.current?.removeEventListener(
          'dragging-changed',
          onTransformControlsDraggingChangedCallback
        )
      }
    }
    return void 0
  })

  return (
    <group>
      <Box
        ref={ref}
        name='LINK-POINT-HANDLE'
        args={[0.2, 0.2, 0.2]}
        position={position}
        material-color={0xffff00}
        material-depthTest={true}
        material-depthWrite={true}
        onClick={onClick}
        onPointerMissed={onPointerMissed}
        onContextMenu={onContextMenu}
      />
      {active && (
        <TransformControls
          // @ts-ignore
          ref={transformControlsRef}
          object={active}
          onChange={onTransformControlsMouseMove}
          // 스냅 설정
          translationSnap={0.1}
          // 조작 모드 포지션 설정만 가능하다.
          mode='translate'
          // showY={false}
        />
      )}
    </group>
  )
}
