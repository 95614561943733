import { Plane } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'
import React from 'react'
import { Box3, ColorRepresentation, DoubleSide } from 'three'
import { Text } from './Text'

let Rects = 0

type Props = {
  color?: ColorRepresentation
  outlineColor?: ColorRepresentation
  outlineWidth?: number
  outlineOpacity?: number
  outlineVisible?: boolean
  width?: number
  height?: number
} & React.ComponentProps<typeof Plane>

export const Rect = React.forwardRef<
  THREE.Object3D<THREE.Event> | undefined,
  Props
>((props: Props, ref) => {
  const [index] = React.useState(Rects++)

  const {
    children,
    position = [0, 0, 0],
    rotation,
    color,
    width = 1,
    height = 1,
    ...rests
  } = props
  const [_position] = React.useState<THREE.Vector3Tuple>([
    position[0],
    position[1] + index * 0.001,
    position[2]
  ])

  const planeRef = React.useRef<THREE.Object3D<THREE.Event> | null>(null)
  const textRef = React.useRef<THREE.Object3D<THREE.Event> | null>(null)

  const [renderWith, setRenderWidth] = React.useState(1)
  const [renderHeight, setRenderHeight] = React.useState(1)

  const [init, setInit] = React.useState(false)
  useFrame(() => {
    if (!init) {
      if (textRef.current) {
        const textBox = new Box3()
        textBox.setFromObject(textRef.current)
        const h = textBox.max.x - textBox.min.x
        const w = textBox.max.z - textBox.min.z
        if (isFinite(h) && isFinite(h)) {
          setInit(true)
          setRenderWidth(Math.max(w * 1.1, width))
          setRenderHeight(Math.max(h * 1.1, height))
        }
      }
    }
  })

  return (
    <group
      name='NETWORK-DIAGRAM-NODE-MODEL'
      rotation={[Math.PI / 2, 0, -Math.PI / 2]}
    >
      <group ref={ref} position={_position} rotation={rotation}>
        <Plane
          args={[renderHeight, renderWith, 1, 1]}
          ref={planeRef}
          {...rests}
          name='NETWORK-DIAGRAM-SHAPE--Rect'
          rotation={[-Math.PI / 2, 0, 0]}
          material-color={color}
          material-side={DoubleSide}
        />
        {children && (
          <Text
            ref={textRef}
            position={[0, 0.001, 0]}
            color='#000000'
            fontWeight={'Bold'}
            fontSize={1}
          >
            TEXT {children}
          </Text>
        )}
      </group>
    </group>
  )
})
