import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import React from 'react'

type Props = {
  isOpen: boolean
  onSubmit: () => void
  onClose: () => void
}

export const DeleteDialog = ({ isOpen, onSubmit, onClose }: Props) => {
  const handleSubmit = () => {
    onSubmit && onSubmit()
  }
  const handleClose = () => {
    onClose && onClose()
  }

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>파일 삭제</DialogTitle>
      <DialogContent>
        <DialogContentText>정말로 삭제하시겠습니까?</DialogContentText>
        <DialogContentText>
          삭제한 데이터는 복구할 수 없습니다.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>취소</Button>
        <Button onClick={handleSubmit} autoFocus>
          파일 삭제
        </Button>
      </DialogActions>
    </Dialog>
  )
}
