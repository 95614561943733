import React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContentText from '@mui/material/DialogContentText'
import TextField from '@mui/material/TextField'

type Props = {
  isOpen: boolean
  onSubmit: (filename: string) => void
  onClose: () => void
}

export const SaveAsNewNameDialog = ({ isOpen, onSubmit, onClose }: Props) => {
  const [filename, setFilename] = React.useState('')
  const [focused, setFocused] = React.useState<string | null>(null)

  const handleSubmit = () => {
    if (filename) {
      onSubmit && onSubmit(filename)
      setFocused(null)
    } else {
      setFocused('filename')
    }
  }

  const handleClose = () => {
    onClose && onClose()
  }

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>다른 이름으로 저장</DialogTitle>
      <DialogContent>
        <DialogContentText>
          현재 파일을 새이름으로 저장합니다.
        </DialogContentText>
        <Box
          component='form'
          sx={{
            '& > :not(style)': { m: 1, width: '25ch' }
          }}
          noValidate
          autoComplete='off'
        >
          <TextField
            autoFocus
            focused={focused === 'filename'}
            required
            error={'' === filename}
            id='outlined-basic'
            label='이름'
            variant='outlined'
            placeholder='이름을 입력하세요'
            onChange={(e) => {
              setFilename((e.target.value || '').trim())
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault()
                handleSubmit()
              }
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>취소</Button>
        <Button disabled={filename === ''} onClick={handleSubmit} autoFocus>
          다른 이름으로 저장
        </Button>
      </DialogActions>
    </Dialog>
  )
}
