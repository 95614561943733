import React from 'react'
import { Text } from '../../../shapes'
import { ChartProps } from '../Chart'
import { Svg } from '../Svg'
import { ClockChart } from './ClockChart'

export const SwitchChart = ({
  value = ['No Name', 'OF / 300', 297],
  color = 0xffffff,
  update
}: ChartProps) => {
  const [_value, setValue] = React.useState(value)

  React.useLayoutEffect(() => {
    setValue(value)
  }, [value])

  React.useLayoutEffect(() => {
    if (update) {
      let isAlive = true
      const _update = () => {
        ;(update() as Promise<any>).then((value) => {
          if (isAlive) {
            setValue(value)
            requestAnimationFrame(_update)
          }
        })
      }
      requestAnimationFrame(_update)
      return () => {
        isAlive = false
      }
    }
    return () => {}
  }, [update])

  return (
    <ClockChart
      value={_value[2]}
      color={color as THREE.ColorRepresentation}
      name='Switch'
    >
      <Text
        position={[-0.3, 0, -1]}
        color={color as THREE.ColorRepresentation}
        fontSize={0.25}
        fontWeight='Bold'
        anchorX={'left'}
        anchorY={'middle'}
      >
        {_value[0]}
      </Text>
      <Text
        position={[0.1, 0, -1]}
        color={color as THREE.ColorRepresentation}
        fontSize={0.25}
        fontWeight='Medium'
        anchorX={'left'}
        anchorY={'middle'}
      >
        {_value[1]}
      </Text>

      <Svg
        color={color as THREE.ColorRepresentation}
        depth={10}
        opacity={1}
        position={[0.35, 0, -0.35]}
        rotation={[0, Math.PI, 0]}
        scale={[1.5, 1.1, 1.5]}
        data={`<svg><path d="M46.82,23.4l-5.95-3.43v2.06h-9.15c-.15-1.34-.62-2.59-1.33-3.66l6.34-6.34,1.45,1.45,1.78-6.63-6.63,1.78,1.45,1.45-6.27,6.27c-1.07-.83-2.34-1.4-3.73-1.63V5.95h2.06L23.41,0l-3.43,5.95h2.06V14.73c-1.39,.23-2.66,.8-3.73,1.63l-6.27-6.26,1.45-1.45-6.63-1.77,1.78,6.63,1.45-1.45,6.34,6.33c-.71,1.07-1.18,2.32-1.33,3.66H5.95v-2.05L0,23.42l5.95,3.43v-2.06H15.25c.27,1.22,.81,2.33,1.55,3.29l-6.71,6.71-1.45-1.45-1.78,6.63,6.63-1.78-1.45-1.45,6.78-6.78c.96,.63,2.05,1.06,3.22,1.26v9.66h-2.06l3.43,5.95,3.43-5.95h-2.06v-9.66c1.17-.2,2.27-.63,3.22-1.26l6.78,6.77-1.45,1.45,6.63,1.77-1.78-6.63-1.45,1.45-6.71-6.7c.74-.96,1.28-2.07,1.55-3.29h9.3v2.05l5.95-3.44Z"/></svg>`}
      />
    </ClockChart>
  )
}

export default SwitchChart
