import React from 'react'
import { Text } from '../../../shapes'
import { ChartProps } from '../Chart'
import Cube from './Cube'

export const ChannelBlockChart = ({
  value = ['No Name', 297],
  color = 0xffffff,
  update
}: ChartProps) => {
  const [_value, setValue] = React.useState(value)

  React.useLayoutEffect(() => {
    setValue(value)
  }, [value])

  React.useLayoutEffect(() => {
    if (update) {
      let isAlive = true
      const _update = () => {
        ;(update() as Promise<any>).then((value) => {
          if (isAlive) {
            setValue(value)
            requestAnimationFrame(_update)
          }
        })
      }
      requestAnimationFrame(_update)
      return () => {
        isAlive = false
      }
    }
    return () => {}
  }, [update])

  return (
    <group name='ChannelBlockChart'>
      <Text
        position={[-0.5, 0, -1]}
        color='#3064be'
        fontSize={0.25}
        fontWeight='Bold'
        anchorX={'left'}
        anchorY={'middle'}
      >
        {_value[0]}
      </Text>
      <Text
        position={[-0.2, 0, -1]}
        color='#3064be'
        fontSize={0.25}
        fontWeight='Medium'
        anchorX={'left'}
        anchorY={'middle'}
      >
        {_value[1]} ch
      </Text>
      <Cube value={_value[1]} color={color as THREE.ColorRepresentation} />
    </group>
  )
}

export default ChannelBlockChart
