import React from 'react'
import { ColorRepresentation } from 'three'
import { Text } from '../../../shapes'
import { ChartProps } from '../Chart'
import { Svg } from '../Svg'

export const ChartBottomLeft = ({
  value = 0,
  color = 0x33aaff,
  update
}: ChartProps) => {
  const [_value, setValue] = React.useState(value)

  React.useLayoutEffect(() => {
    setValue(value)
  }, [value])

  React.useLayoutEffect(() => {
    if (update) {
      let isAlive = true
      const _update = () => {
        ;(update() as Promise<any>).then((value) => {
          if (isAlive) {
            setValue(value)
            requestAnimationFrame(_update)
          }
        })
      }
      requestAnimationFrame(_update)
      return () => {
        isAlive = false
      }
    }
    return () => {}
  }, [update])

  return (
    <group name='ChartBottomLeft'>
      <Text
        position={[0.685, 0, -1.56]}
        color={color as ColorRepresentation}
        fontSize={0.15}
        fontWeight='Bold'
      >
        {_value}
      </Text>
      <Svg
        depth={0.1}
        color={color as ColorRepresentation}
        depthWrite={false}
        data={`<svg>
  <g>
      <g>
          <g>
              <path 
                  d="M204.07,27.32c-7.79-7.64-20.42-7.68-28.14-.07-7.72,7.6-7.64,19.99,.16,27.61,7.8,7.62,20.4,7.64,28.11,.05,2.41-2.37,4.07-5.22,4.96-8.24,1.95-6.63,.26-14.11-5.09-19.35Zm-4.93,22.64c-4.95,4.87-13.04,4.85-18.04-.04-5.01-4.89-5.05-12.84-.1-17.71,4.95-4.88,13.05-4.85,18.05,.04,3.43,3.36,4.53,8.17,3.27,12.42-.57,1.94-1.63,3.76-3.18,5.29Z" />
              <path 
                  d="M134.75,58.83l-5.3-5.16-5.22,5.14,2.25,2.19-20.99,20.67-55.54-.07-4.64-4.49c1.25-1.63,1.12-3.95-.42-5.44-1.68-1.62-4.37-1.63-6.01,0-1.64,1.62-1.61,4.25,.07,5.87,1.54,1.49,3.92,1.6,5.58,.36l4.98,4.82,56.47,.07,21.32-20.99,2.25,2.19,5.22-5.14Z" />
              <path 
                  d="M220.92,4.17C220.91,1.88,219.01,0,216.67,0c-2.34,0-4.22,1.85-4.21,4.15,0,2.11,1.62,3.84,3.68,4.12l.22,52.58-30.96,30.44-177.89-.19-.04-3.09H0s.1,7.28,.1,7.28H7.57s-.04-3.08-.04-3.08l178.35,.18,31.61-31.08-.22-53.04c2.07-.27,3.66-1.98,3.65-4.09Z" />
              <polygon 
                  points="222.46 126.43 222.44 119.2 215.04 119.2 215.06 122.26 209.96 122.26 185.64 98.56 65.21 98.45 65.18 95.36 57.73 95.36 57.81 102.63 65.26 102.64 65.23 99.55 185.18 99.65 209.51 123.36 215.06 123.36 215.08 126.43 222.46 126.43" />
              <g>
                  <path 
                      d="M179.77,72.02l-1.03-1.01h-1.91s-1.02,1-1.02,1v1.85s1.04,1.01,1.04,1.01h1.91s1.02-1,1.02-1v-1.85Zm-1.49,1.77h-.98s-.39-.36-.39-.36v-.98s.38-.36,.38-.36h.98s.39,.36,.39,.36v.98s-.38,.36-.38,.36Z" />
                  <path 
                      d="M173.45,70.14l1.02-1v-1.87s-1.04-1.01-1.04-1.01h-1.91s-1.02,1-1.02,1v1.87s1.04,1.01,1.04,1.01h1.91Zm-1.46-2.8h.98s.39,.36,.39,.36v.98s-.38,.36-.38,.36h-.98s-.39-.36-.39-.36v-.98s.38-.36,.38-.36Z" />
                  <polygon  points="177.36 65.4 171.42 75.72 172.94 75.72 178.87 65.4 177.36 65.4" />
              </g>
          </g>
          <g>
              <g>
                  <rect  x="125.09" y="125.57" width="1.46" height="28.44"
                      transform="translate(-14.24 265.32) rotate(-89.88)" />
                  <rect  x="124.65" y="159.97" width="1.46" height="28.41"
                      transform="translate(-48.98 299.29) rotate(-89.92)" />
                  <polygon 
                      points="56.58 138.92 53.2 138.91 53.27 174.8 56.22 174.8 56.21 173.34 54.25 173.34 54.19 140.37 56.58 140.38 56.58 138.92" />
                  <polygon 
                      points="194.88 139.21 194.88 140.67 222.81 140.73 222.81 140.64 223.29 140.64 223.3 139.28 194.88 139.21" />
                  <polygon  points="194.35 175 222.67 175.04 222.68 173.59 194.36 173.55 194.35 175" />
              </g>
              <g>
                  <polygon  points="65.82 137.44 65.87 170.35 68.65 170.35 68.61 137.45 65.82 137.44" />
                  <rect  x="153.78" y="152.7" width="32.84" height="2.78"
                      transform="translate(15.88 324.07) rotate(-89.92)" />
                  <polygon  points="88.76 143.46 88.78 170.38 91.55 170.39 91.54 143.47 88.76 143.46" />
                  <rect  x="167.57" y="160.81" width="16.65" height="2.77"
                      transform="translate(13.42 337.82) rotate(-89.91)" />
                  <rect  x="148.07" y="152.69" width="32.84" height="2.77"
                      transform="translate(10.16 318.33) rotate(-89.91)" />
                  <rect  x="173.27" y="160.82" width="16.65" height="2.77"
                      transform="translate(19.09 343.52) rotate(-89.9)" />
                  <polygon 
                      points="123.1 170.43 125.87 170.44 125.88 154.65 123.11 154.64 123.1 170.43" />
                  <polygon 
                      points="105.94 149.43 105.94 170.41 108.72 170.41 108.72 149.44 105.94 149.43" />
                  <rect  x="135.77" y="163.21" width="11.73" height="2.77"
                      transform="translate(-23.18 305.99) rotate(-89.91)" />
                  <polygon  points="77.29 137.47 77.32 170.36 80.1 170.37 80.07 137.47 77.29 137.47" />
                  <polygon  points="71.56 137.45 71.6 170.36 74.38 170.36 74.34 137.46 71.56 137.45" />
                  <polygon  points="83.02 143.45 83.05 170.37 85.83 170.38 85.81 143.45 83.02 143.45" />
                  <polygon  points="94.49 153.22 94.5 170.39 97.27 170.39 97.27 153.23 94.49 153.22" />
                  <polygon 
                      points="111.66 170.42 114.43 170.42 114.44 154.63 111.66 154.62 111.66 170.42" />
                  <polygon 
                      points="117.37 170.43 120.16 170.43 120.16 154.64 117.38 154.63 117.37 170.43" />
                  <rect  x="130.05" y="163.2" width="11.73" height="2.78"
                      transform="translate(-28.89 300.25) rotate(-89.91)" />
                  <rect  x="141.48" y="163.22" width="11.73" height="2.77"
                      transform="translate(-17.51 311.68) rotate(-89.91)" />
                  <rect  x="150.21" y="160.54" width="17.12" height="2.77"
                      transform="translate(-3.4 320.45) rotate(-89.91)" />
                  <polygon  points="100.21 149.42 100.22 170.4 103 170.4 103 149.43 100.21 149.42" />
                  <rect  x="125.78" y="164.64" width="8.84" height="2.77"
                      transform="translate(-36.02 295.98) rotate(-89.92)" />
                  <rect  x="144.5" y="160.53" width="17.13" height="2.78"
                      transform="translate(-9.1 314.72) rotate(-89.91)" />
                  <polygon 
                      points="197.3 170.55 200.06 170.55 200.09 161.87 197.32 161.87 197.3 170.55" />
                  <polygon 
                      points="202.99 170.56 205.77 170.56 205.8 161.88 203.02 161.88 202.99 170.56" />
                  <polygon 
                      points="208.74 158.86 208.7 170.57 211.46 170.57 211.5 158.86 208.74 158.86" />
                  <rect  x="182.95" y="164.8" width="8.68" height="2.78"
                      transform="translate(20.81 353.22) rotate(-89.91)" />
                  <polygon 
                      points="191.6 170.54 194.36 170.54 194.39 161.86 191.62 161.86 191.6 170.54" />
              </g>
              <g>
                  <polygon  points="65.84 150.95 65.87 170.35 68.65 170.35 68.63 150.96 65.84 150.95" />
                  <rect  x="160.51" y="159.44" width="19.35" height="2.78"
                      transform="translate(9.12 330.79) rotate(-89.92)"/>
                  <polygon  points="88.77 154.51 88.78 170.38 91.55 170.39 91.54 154.52 88.77 154.51" />
                  <rect  x="170.98" y="164.22" width="9.82" height="2.77"
                      transform="translate(9.99 341.22) rotate(-89.91)" />
                  <rect  x="154.8" y="159.44" width="19.36" height="2.77"
                      transform="translate(3.4 325.05) rotate(-89.91)" />
                  <rect  x="176.68" y="164.23" width="9.81" height="2.77"
                      transform="translate(15.66 346.92) rotate(-89.9)" />
                  <polygon 
                      points="123.1 170.43 125.87 170.44 125.88 161.13 123.1 161.13 123.1 170.43" />
                  <polygon 
                      points="105.94 158.04 105.94 170.41 108.72 170.41 108.72 158.05 105.94 158.04" />
                  <rect  x="138.17" y="165.62" width="6.91" height="2.77"
                      transform="translate(-25.59 308.39) rotate(-89.91)" />
                  <polygon  points="77.3 150.97 77.32 170.36 80.1 170.37 80.08 150.98 77.3 150.97" />
                  <polygon  points="71.58 150.96 71.6 170.36 74.38 170.36 74.36 150.97 71.58 150.96" />
                  <polygon  points="83.03 154.5 83.05 170.37 85.83 170.38 85.82 154.51 83.03 154.5" />
                  <polygon  points="94.49 160.27 94.5 170.39 97.27 170.39 97.27 160.28 94.49 160.27" />
                  <polygon 
                      points="111.66 170.42 114.43 170.42 114.44 161.11 111.66 161.11 111.66 170.42" />
                  <polygon 
                      points="117.37 170.43 120.16 170.43 120.16 161.12 117.38 161.12 117.37 170.43" />
                  <rect  x="132.46" y="165.61" width="6.91" height="2.78"
                      transform="translate(-31.3 302.65) rotate(-89.91)" />
                  <rect  x="143.88" y="165.63" width="6.91" height="2.77"
                      transform="translate(-19.92 314.08) rotate(-89.91)" />
                  <rect  x="153.72" y="164.06" width="10.09" height="2.77"
                      transform="translate(-6.92 323.95) rotate(-89.91)" />
                  <polygon  points="100.21 158.03 100.22 170.4 103 170.4 103 158.04 100.21 158.03" />
                  <rect  x="127.59" y="166.45" width="5.21" height="2.77"
                      transform="translate(-37.83 297.79) rotate(-89.92)" />
                  <rect  x="148.01" y="164.04" width="10.1" height="2.78"
                      transform="translate(-12.62 318.22) rotate(-89.91)" />
                  <polygon 
                      points="197.3 170.55 200.06 170.55 200.08 165.43 197.31 165.43 197.3 170.55" />
                  <polygon 
                      points="202.99 170.56 205.77 170.56 205.78 165.44 203.01 165.44 202.99 170.56" />
                  <polygon 
                      points="208.72 163.66 208.7 170.57 211.46 170.57 211.48 163.67 208.72 163.66" />
                  <polygon 
                      points="185.89 170.53 188.67 170.54 188.68 165.42 185.91 165.41 185.89 170.53" />
                  <polygon 
                      points="191.6 170.54 194.36 170.54 194.38 165.43 191.61 165.42 191.6 170.54" />
              </g>
          </g>
      </g>
  </g>
  </svg>`}
      />
    </group>
  )
}
