import { a, easings, useSpring } from '@react-spring/three'
import { Text } from '@react-three/drei'
import React, { forwardRef, useLayoutEffect, useState } from 'react'

import { IModelProps } from '../../../node/Model'
import { Arc } from '../Arc'

const Model = forwardRef((props: IModelProps, ref: any) => {
  const [angles, setAngles] = useState<number[]>([])
  const counts = 40
  const angle = 360 / counts

  const [angles2, setAngles2] = useState<number[]>([])
  const counts2 = 17
  const angle2 = 360 / counts2

  const [angles3, setAngles3] = useState<number[]>([])
  const counts3 = 2
  const angle3 = 360 / counts3

  useLayoutEffect(() => {
    const angles = []
    for (let i = 0; i < counts; i++) {
      angles.push(i * angle)
    }
    setAngles(angles)

    const angles2 = []
    for (let i = 0; i < counts2; i++) {
      angles2.push(i * angle2)
    }
    setAngles2(angles2)

    const angles3 = []
    for (let i = 0; i < counts3; i++) {
      angles3.push(i * angle3)
    }
    setAngles3(angles3)
  }, [])

  const { rotate1 } = useSpring({
    config: { duration: 1000, easing: easings.easeInCirc },
    loop: true,
    from: { rotate1: 0 },
    to: { rotate1: (-Math.PI * 2) / counts }
  })

  const { rotate2 } = useSpring({
    loop: true,
    from: { rotate2: 0 },
    to: { rotate2: -Math.PI * 2 },
    config: { duration: 2000 }
  })

  return (
    <group
      name='NETWORK-DIAGRAM-CHART'
      ref={ref}
      position={props.position}
      rotation={[-Math.PI / 2, 0, 0]}
      scale={props.scale}
    >
      <a.group rotation-z={rotate1}>
        {angles.map((_angle, i) => {
          // let opacity = i / angles.length
          // let opacity = 1 - i / angles.length
          let opacity =
            Math.sin((Math.PI * i) / angles.length) * Math.log10(i - angle / 2)

          return (
            <Arc
              key={i}
              aRadius={1}
              aRadius2={0.8}
              aStartAngle={_angle - angle}
              aEndAngle={_angle - angle / 2}
              color={'#1f91be'}
              aClockwise={true}
              animate={false}
              opacity={opacity}
            />
          )
        })}
      </a.group>
      <group>
        {angles2.map((angle, i) => (
          <Arc
            key={i}
            aRadius={0.7}
            aRadius2={0.675}
            aStartAngle={angle - 30}
            aEndAngle={angle - 15}
            color={'#1f91be'}
            aClockwise={true}
            animate={false}
          />
        ))}
      </group>
      <a.group rotation-z={rotate2}>
        {angles3.map((angle, i) => (
          <Arc
            key={i}
            aRadius={0.3}
            aRadius2={0.2}
            aStartAngle={angle - 120}
            aEndAngle={angle - 60}
            color={'#1f91be'}
            aClockwise={true}
            animate={false}
          />
        ))}
      </a.group>

      <Text
        color={0xffffff}
        fontSize={0.2}
        letterSpacing={0}
        textAlign={'center'}
        anchorX={'center'}
        anchorY={'middle'}
        font={`${process.env.REACT_APP_BASE_URL || ''}assets/fonts/KT-Bold.ttf`}
        material-depthTest={false}
        material-depthWrite={false}
        outlineWidth={'15%'}
        outlineColor='#0f4e9f'
        outlineOpacity={0}
      >
        MUX
      </Text>
    </group>
  )
})

export default Model
