import React from 'react'
import {
  Color,
  EdgesGeometry,
  ExtrudeBufferGeometry,
  Group,
  LineBasicMaterial,
  LineSegments,
  Mesh,
  MeshStandardMaterial
} from 'three'
import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader'

type SvgProps = {
  data: string
  color?: THREE.ColorRepresentation
  opacity?: number
  depthWrite?: boolean
  edgeColor?: THREE.ColorRepresentation
  edgeWidth?: number
  edgeOpacity?: number
  depth?: number
  bevelEnabled?: boolean
  bevelOffset?: number
  bevelSegments?: number
  bevelThickness?: number
  bevelSize?: number
  position?: THREE.Vector3Tuple
  rotation?: THREE.Vector3Tuple
  scale?: THREE.Vector3Tuple
}

export const Svg = ({
  data,
  color = 0x33aaff,
  opacity = 0.25,
  edgeColor = 0x000000,
  edgeOpacity = 0,
  depth = 0,
  bevelEnabled = true,
  bevelOffset = 0,
  bevelSegments = 0,
  bevelThickness = 0,
  bevelSize = 0,
  position = [0, 0, 0],
  rotation = [0, 0, 0],
  scale = [1, 1, 1],
  depthWrite = true
}: SvgProps) => {
  const fillMaterial = React.useMemo(() => {
    return new MeshStandardMaterial({
      color: new Color(color).convertSRGBToLinear(),
      transparent: true,
      opacity: opacity,
      depthWrite: depthWrite
    })
  }, [color, opacity])

  const stokeMaterial = React.useMemo(() => {
    return new LineBasicMaterial({
      color: new Color(edgeColor).convertSRGBToLinear(),
      transparent: true,
      opacity: edgeOpacity
    })
  }, [edgeColor, edgeOpacity])

  const svgGroup = React.useMemo(() => {
    const loader = new SVGLoader()
    const svgData = loader.parse(data)
    const group = new Group()
    group.scale.y *= -1
    svgData.paths.forEach((path) => {
      const shapes = SVGLoader.createShapes(path)
      shapes.forEach((shape) => {
        const meshGeometry = new ExtrudeBufferGeometry(shape, {
          depth: depth,
          bevelEnabled: bevelEnabled,
          bevelOffset: bevelOffset,
          bevelSegments: bevelSegments,
          bevelThickness: bevelThickness,
          bevelSize: bevelSize
        })
        meshGeometry.scale(0.01, 0.01, 0.01)
        const linesGeometry = new EdgesGeometry(meshGeometry)
        const mesh = new Mesh(meshGeometry, fillMaterial)
        const lines = new LineSegments(linesGeometry, stokeMaterial)
        group.add(mesh, lines)
      })
    })
    return group
  }, [data])

  return (
    <group position={position} rotation={rotation} scale={scale}>
      <group rotation={[-Math.PI / 2, 0, Math.PI / 2]}>
        <primitive object={svgGroup} />
      </group>
    </group>
  )
}
